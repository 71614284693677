import { ReactComponent as Balance } from "../../images/affiliateImages/balance.svg";
import { ReactComponent as EarnedAll } from "../../images/affiliateImages/earnedAllTime.svg";
import { ReactComponent as EarnedMonth } from "../../images/affiliateImages/earnedLastMonth.svg";
import { ReactComponent as MyReferralsIcon } from "../../images/affiliateImages/my-referrals.svg";
import { ReactComponent as OpenMailIcon } from "../../images/affiliateImages/open-mail-icon.svg";
import { ReactComponent as Payout } from "../../images/affiliateImages/payout.svg";
import { ReactComponent as ShareIcon } from "../../images/affiliateImages/tip-1-icon.svg";
import payonerLogo from "../../images/payonerLogo.png";
import paypallogo from "../../images/paypallogo.png";
import venmoLogo from "../../images/venmoLogo.png";

const ALREADY_EXIST_URL = "/affiliate/already-exists";
const PROFILE_URL = "/affiliate/profile";
const SURVEY_URL = "/affiliate/survey";

export const getRedirectionURL = ({ existing, hasExisting, hadSurvey }) => {
  let redirectUrl;
  let state;
  switch (true) {
    case !existing && !hadSurvey && hasExisting === undefined: // NOTE: 1st scenario
      redirectUrl = ALREADY_EXIST_URL;
      break;
    case !existing && !hadSurvey && hasExisting !== undefined && !hasExisting: // NOTE: 2nd scenario
      redirectUrl = SURVEY_URL;
      break;
    case !existing && hasExisting && !hadSurvey: // NOTE: 3rd scenario
      redirectUrl = ALREADY_EXIST_URL;
      state = {
        isExist: true,
      };
      break;
    case !existing && !hasExisting && hadSurvey: // NOTE: 4th scenario
      redirectUrl = PROFILE_URL;
      break;
    case existing && hasExisting && hadSurvey: // NOTE: 5th scenario
      redirectUrl = PROFILE_URL;
      break;
    case existing && !hasExisting && !hadSurvey: // NOTE: 6th scenario
      redirectUrl = SURVEY_URL;
      break;
    default:
      redirectUrl = PROFILE_URL; // NOTE: default scenario
      break;
  }
  return { redirectUrl, state };
};

export const summaryCards = [
  {
    title: (
      <div className="d-flex">
        <EarnedAll />
        <span className="text-wrap" style={{ flex: "1", marginTop: "-5px" }}>
          Earned - All Time
        </span>
      </div>
    ),
    name: "totalEarned",
  },
  {
    title: (
      <div className="d-flex">
        <EarnedMonth />
        <span className="text-wrap" style={{ flex: "1", marginTop: "-5px" }}>
          Earned - Last Month
        </span>
      </div>
    ),
    name: "lastMonthEarned",
  },
  {
    title: (
      <div className="d-flex">
        <Payout />
        <span className="text-wrap" style={{ flex: "1", marginTop: "-5px" }}>
          Payout - All Time
        </span>
      </div>
    ),
    name: "payoutAllTime",
  },
  {
    title: (
      <div className="d-flex">
        <Payout />
        <span className="text-wrap" style={{ flex: "1", marginTop: "-5px" }}>
          Payout - Last Month
        </span>
      </div>
    ),
    name: "payoutLastMonth",
  },
  {
    title: (
      <div className="d-flex">
        <Balance />
        <span className="text-wrap" style={{ flex: "1", marginTop: "-5px" }}>
          My Balance
        </span>
      </div>
    ),
    name: "myBalance",
    hasButton: true,
    btnTitle: "Request Payout",
  },
];

export const paymentMethodLogo = {
  venmo: venmoLogo,
  paypal: paypallogo,
  payoneer: payonerLogo,
};

export const rulesCardData = [
  {
    title: (
      <>
        <ShareIcon style={{ color: "#3483FA" }} />
        <span>Step 1</span>
      </>
    ),
    desc: "Share Your Referral Link OR Promo Code With Landscape Business Owners.",
  },
  {
    title: (
      <>
        <OpenMailIcon style={{ color: "#3483FA" }} />
        <span>Step 2</span>
      </>
    ),
    desc: "Anytime Anyone Uses Your Link Or Promo Code They Will Be Shown On the Overview Below.",
  },
  {
    title: (
      <>
        <MyReferralsIcon style={{ color: "#3483FA" }} />
        <span>Step 3</span>
      </>
    ),
    desc: "Receive A 3-5% Monthly Commission For Every Active ZevBit User Who Signs Up With Your Link Or Promo Code",
  },
];

export const rules = [
  "Share your ZevBit Referral Link or Promo Code with contractors who are not yet using ZevBit.",
  "Referrals must sign up for any ZevBit Plan using your Referral Link or Promo Code. You will receive a 5% monthly commission for anyone who signs up for a free trial and purchases ZevBit. You will receive a 3% monthly commission if the referral books a demo using your link and then signs up.",
  "You will continue receiving the monthly commission for as long as your referrals have an active subscription with ZevBit.",
  "The commission is calculated based on the amount the referral pays each month (After Discounts & Excluding Taxes)",
  "You will not get commission for a referral if they do not use your referral link or Promo Code.",
];
export const emailCategories = [
  "Estimate",
  "Signed Estimate",
  "Due Invoice",
  "Overdue Invoice",
  "Not Due Invoice",
  "Recorded Payment Receipt",
  "QB/Stripe Payment Receipt",
  "Change Order",
  "Signed Change Order",
  "Credit",
  "Project Summary",
  "Due Date Reminders",
  "Overdue Reminders",
].map((value) => ({ value, label: value }));
