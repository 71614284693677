/* eslint-disable no-unused-vars */
import { message } from "antd";

import { FALSY_CONSTANTS } from "./constants/constants";
import regex from "./regex";

export const convertJSONToCSV = (data) => {
  const csvRows = [];
  const headers = Object.keys(data[0]);

  // Add header row
  csvRows.push(headers.join(","));

  // Add data rows
  for (const row of data) {
    const values = headers.map((header) => row[header]);
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

export const checkUndefinedNull = (val) => val === null || val === undefined;

export const contactFormat = (contact) => {
  let char;
  if (contact) {
    const contactStr =
      contact?.length > 10
        ? contact?.toString()?.slice(1)
        : contact?.toString();
    const numbers = contactStr.replace(/\D/g, "");
    if (contact.length) {
      char = { 0: "(", 3: ") ", 6: "-" };
    }
    contact = "";
    for (let i = 0; i < numbers.length; i++) {
      contact += (char[i] || "") + numbers[i];
    }
  }
  return contact;
};

export const cardNumber = (number) => {
  const char = { 4: " ", 9: " ", 14: " " };
  let cloned = "";

  const alphaCheck = /[a-zA-Z]/g;
  if (alphaCheck.test(number)) {
    return number?.slice(0, -1);
  }

  if (number?.length) {
    for (let i = 0; i < number.length; i++) {
      if (char?.[i] && number?.[i] !== char?.[i]) {
        cloned += char?.[i] + number?.[i];
      } else {
        cloned = cloned ? cloned + number?.[i] : number?.[i];
      }
    }
  }
  return cloned.trim();
};

export const cardExpiryFormat = (number) => {
  const char = { 2: "/" };
  let cloned = "";

  const alphaCheck = /[a-zA-Z]/g;
  if (alphaCheck.test(number)) {
    return number?.slice(0, -1);
  }

  if (number?.length) {
    for (let i = 0; i < number.length; i++) {
      if (char?.[i] && number?.[i] !== char?.[i]) {
        cloned += char?.[i] + number?.[i];
      } else {
        cloned = cloned ? cloned + number?.[i] : number?.[i];
      }
    }
  }
  return cloned.trim();
};

export const removeFormat = (value) => value?.replace(/\D/g, "");

export const checkFalsyConstant = (val) => FALSY_CONSTANTS.includes(val);

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
  message.success("Copied to clipboard");
};

export const trimNumber = (value) => {
  const number = Math.trunc(value * 100) / 100 || 0;
  return number?.toFixed(2);
};

export const passwordLengthValidation = () => ["Please enter your password!"];

export const validatePassCond = (value) => {
  const err = [];
  if (value?.length < 8) {
    err.push("Password must be at least 8 characters.");
  }
  if (!regex.checkSpecialChar(value)) {
    err.push("Password must contain at least one special character.");
  }
  if (!regex.checkLowerCase(value)) {
    err.push("Password must contain lowercase letter.");
  }
  if (!regex.checkUpperCase(value)) {
    err.push("Password must contain uppercase letter.");
  }
  if (!regex.checkNumber(value)) {
    err.push("Password must contain at least one number.");
  }
  if (/\s/.test(value)) {
    err.push("Password cannot contain spaces.");
  }
  return err?.length ? err : false;
};

export const convertToNumber = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = parseInt(value, 10);
    return acc;
  }, {});
export const convertToUpperCase = (value) =>
  `${value?.[0]?.toUpperCase()}${value?.slice(1)}`;
export const modifyPhoneNumber = (value) =>
  value
    ? `${value?.slice(1).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`
    : "-";

export const toCapitalize = (value) =>
  value?.charAt(0).toUpperCase() + value?.slice(1);

export const formatString = (string) =>
  string?.replace(/([a-z])([A-Z])/g, "$1 $2");

export const formatCardNumber = (value) => {
  console.log("value", value);
  return value?.replace(
    /([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})/,
    "$1 $2 $3 $4",
  );
};
