/* eslint-disable no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Select, Upload } from "antd";
import { EditorState } from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../api/api";
import { sendChangeOrderToCustomerAPI } from "../../../api/estimation";
import { getChangeOrderTemplateDetail } from "../../../api/invoice";
import {
  addCustomOptionsActions,
  getTagsDetails,
  receiptEmailDetails,
  showBannerAction,
} from "../../../redux/invoice/invoice.action";
import {
  ATTACHMENT_TYPES,
  MAX_ATTACHMENT_SIZE,
} from "../../../utils/constants/constants";
import regex from "../../../utils/regex";
import SmallLoader from "../../loader/smallLoader";
import { invoiceEmailCategory } from "../helper";
import { invoiceSendIcon } from "../svg.icons";
const SendCustomerModal = ({
  handleCancel,
  handleBack,
  isPaid = false,
  paymentIds,
  isPayment,
  action,
  defaultEmail,
  changeOrderSentId,
  selectedServicesIds,
  actualServicesId,
  receiptType,
  files,
  setFiles,
  fileInputRef,
  fileSizeError,
  setFileSizeError,
  validFileError,
  setValidFileError,
}) => {
  const { emailCategory } = useSelector((state) => state?.invoice);
  const dispatch = useDispatch();
  const [html, setHtml] = useState(EditorState.createEmpty());
  const emailTemplateDetails = useSelector(
    (state) => state?.invoice?.receiptDetails,
  );
  const receiptDetails = useSelector(
    (state) => state?.invoice?.sendReceipt?.receiptData,
  );
  const [isProjectSummary] = useState(
    action === invoiceEmailCategory.projectSummary,
  );
  const [bodyLoading, setBodyLoading] = useState(false);
  const [emailSubject, setEmailSubject] = useState(receiptDetails?.subject);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const invoiceId = new URLSearchParams(location.search).get("id");
  const estimationId = new URLSearchParams(location.search).get("estimationId");
  const [validationError, setValidationError] = useState({
    email: false,
    subject: false,
  });
  const [title, setTitle] = useState({});
  const [suggestedEmail, setSuggestedEmail] = useState([]);
  const inputRef = useRef(null);
  const handleChange = (value) => {
    if (action === invoiceEmailCategory.changeOrder) {
      getChangeOrderTemplateDetail(value);
    } else {
      getReceiptDetails(value);
    }
  };

  const getReceiptDetails = async (id) => {
    setBodyLoading(true);
    const templateId =
      id || emailCategory?.find((obj) => obj?.defaultEnabled)?._id;
    const response = await api.request({
      url: `v3/invoice/${
        isPayment
          ? "payment/payment-receipt-email-template"
          : isProjectSummary
          ? "summary-template"
          : "invoice-email-template"
      }?${
        isPayment
          ? "paymentId"
          : isProjectSummary
          ? "estimationId"
          : "invoiceId"
      }=${
        isProjectSummary ? estimationId : invoiceId
      }&templateId=${templateId}`,
      method: "GET",
    });
    if (response.remote === "success") {
      dispatch(receiptEmailDetails(response.data.data));
    }
    setBodyLoading(false);
  };
  const getChangeOrderTemplateDetailHandler = async (id) => {
    setBodyLoading(true);
    const templateId =
      id || emailCategory?.find((obj) => obj?.defaultEnabled)?._id;
    const response = await getChangeOrderTemplateDetail({
      templateId,
      changeOrderSentId,
      selectedServicesIds,
      actualServicesId,
      estimationId,
    });
    if (response.remote === "success") {
      dispatch(receiptEmailDetails(response.data.data));
    }
    setBodyLoading(false);
  };
  const handleCustomerEmailChange = (values) => {
    values?.forEach((val) => {
      dispatch(addCustomOptionsActions(val));
      if (!suggestedEmail?.find((obj) => obj?.value === val)) {
        setSuggestedEmail((prev) => [...prev, { label: val, value: val }]);
      }
    });
    const hasInvalidEmail = values.some((val) => !regex.emailRegex.test(val));
    setValidationError((prev) => ({
      ...prev,
      email: !values?.length,
    }));
    if (!hasInvalidEmail) {
      setSelectedEmails(values);
    } else {
      message.error("Please select a valid email");
    }
  };

  const handleEmailTemplateChange = (id) => {
    setHtml(document.getElementById(id).innerHTML);
  };

  const handleEmailSubjectChange = ({ target: { value } }) => {
    setEmailSubject(value);
    setValidationError((prev) => ({
      ...prev,
      subject: !value,
    }));
  };

  const handleFileUpload = (e) => {
    const { files: targetFiles } = e?.target;
    const fileList = [...targetFiles];
    if (targetFiles) setFiles(fileList);
    const clonedFiles = [...files];
    if (clonedFiles?.length) {
      clonedFiles?.push(...fileList);
      const newDataTransfer = new DataTransfer();
      clonedFiles?.forEach((file) => newDataTransfer.items.add(file));
      inputRef.current.files = newDataTransfer?.files;
      setFiles([...clonedFiles]);
    }
    const totalFileSize = fileList?.reduce((acc, curr) => acc + curr?.size, 0);
    const maxSizeInMb = 25 * 1024 ** 2;
    const validateFiles = (fileList) =>
      fileList.every((file) =>
        ATTACHMENT_TYPES.some((fileType) => file?.type?.includes(fileType)),
      );
    if (totalFileSize > maxSizeInMb) {
      setFileSizeError(true);
    } else {
      setFileSizeError(false);
    }
    if (!validateFiles(fileList)) {
      setValidFileError(true);
    } else {
      setValidFileError(false);
    }
  };

  const handleRemoveFile = (ind) => {
    const clonedFile = [...files];
    clonedFile.splice(ind, 1);
    setFiles([...clonedFile]);
    const newFiles = new DataTransfer();
    clonedFile?.forEach((file) => newFiles?.items.add(file));
    inputRef.current.files = newFiles.files;
  };

  const handleSendToCustomer = async () => {
    if (!selectedEmails?.length) {
      setValidationError((prev) => ({ ...prev, email: true }));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    if (isPaid) {
      formData.append("paymentIds", JSON.stringify(paymentIds));
    }
    if (isProjectSummary) {
      formData.append("estimationId", estimationId);
    }
    if (!isPaid && !isProjectSummary) {
      formData.append("invoiceId", invoiceId);
    }
    formData.append("emails", JSON.stringify(selectedEmails));
    formData.append("subject", emailSubject || emailTemplateDetails?.subject);
    formData.append("body", html);
    console.log("files => ", files);
    if (files?.length) {
      files?.forEach((file) => {
        formData.append("attachment", file);
      });
    }
    const response = await api.request({
      url: `v3/invoice/${
        isPaid
          ? "payment/send-payment-receipt"
          : isProjectSummary
          ? "send-summary-email"
          : "send-invoice"
      }`,
      method: "POST",
      // data: payload,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setLoading(false);
    if (response.remote === "success") {
      handleBack();
      dispatch(showBannerAction({ type: receiptType, show: true }));
      handleCancel();
    }
  };
  const handleSendMail = async () => {
    const formData = new FormData();
    if (!selectedEmails?.length) {
      setValidationError((prev) => ({ ...prev, email: true }));
      return;
    }
    setLoading(true);
    const hideLoadingMessage = message.loading("Processing...", 0);
    formData.append("subject", emailSubject);
    formData.append("leadEmails", JSON.stringify(selectedEmails));
    formData.append("body", html);
    formData.append("selectedServices", JSON.stringify(selectedServicesIds));
    formData.append("actualServicesId", actualServicesId.join(","));
    if (files?.length) {
      files?.forEach((file) => {
        formData.append("attachment", file);
      });
    }
    const payload = {
      estimationId,
      sentChangeOrderId: changeOrderSentId,
      data: formData,
    };
    hideLoadingMessage();
    const res = await sendChangeOrderToCustomerAPI(payload);
    if (res.remote === "success") {
      message.success("Email Sent Successfully");
      handleCancel();
    } else {
      message.error(res.errors?.errors?.error || "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (action === invoiceEmailCategory.changeOrder) {
      getChangeOrderTemplateDetailHandler();
    } else {
      getReceiptDetails();
    }
  }, [action]);

  useEffect(() => {
    if (emailTemplateDetails?.body) {
      setHtml(emailTemplateDetails?.body);
    }
    if (emailTemplateDetails?.subject) {
      setEmailSubject(emailTemplateDetails?.subject);
      setValidationError((prev) => ({ ...prev, subject: false }));
      if (emailTemplateDetails?.email) {
        setSelectedEmails([emailTemplateDetails?.email]);
      }
      if (!emailTemplateDetails?.suggestedEmails?.length) {
        setSuggestedEmail(
          [emailTemplateDetails?.email]?.map((val) => ({
            label: val,
            value: val,
          })),
        );
      } else {
        setSuggestedEmail(
          emailTemplateDetails?.suggestedEmails?.map((val) => ({
            label: val,
            value: val,
          })),
        );
      }
    }
  }, [emailTemplateDetails]);

  useEffect(() => {
    if (defaultEmail) {
      setSelectedEmails([defaultEmail]);
    }
  }, [defaultEmail]);
  useEffect(() => {
    if (estimationId || invoiceId) {
      dispatch(getTagsDetails(estimationId || invoiceId));
    }
  }, [estimationId, invoiceId]);

  const getTitle = {
    "send-invoice": {
      title: "Send Invoice",
      desc: "Send Your Invoice Order Via Email",
    },
    "send-receipt": {
      title: "Send Receipt",
      desc: "Send Your Receipt Via Email",
    },
    "send-project-summary": {
      title: "Send Project Summary",
      desc: "Send Your Project Summary Via Email",
    },
  };

  useEffect(() => {
    const title = getTitle[location.pathname?.replace("/", "")];
    setTitle(title);
  }, [location.pathname]);

  const maxSize = 25 * 1024 * 1024;

  return (
    <>
      <div className="invoie-send-customer">
        <div className="d-flex align-items-center justify-content-between gap-3 mb-2 flex-wrap">
          <h3>{title?.title}</h3>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <Button
              className="clear-filter"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              className="apply-btn"
              type="primary"
              onClick={() => {
                if (action === invoiceEmailCategory.changeOrder) {
                  handleSendMail();
                } else {
                  handleSendToCustomer();
                }
              }}
              disabled={
                loading || fileSizeError || validFileError || bodyLoading
              }
              loading={loading}
            >
              {invoiceSendIcon}
              <span className="ms-2">Send to Customer</span>
            </Button>
          </div>
        </div>
        {/* Customer email list */}
        <div>
          <h4>{title?.desc}</h4>
          <div className="form-group select_payment mb-2">
            <label>Customer&lsquo;s Email:</label>
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              size="large"
              maxTagCount="responsive"
              value={selectedEmails}
              onChange={(value) => handleCustomerEmailChange(value)}
              options={suggestedEmail}
              className="email-tags"
              defaultActiveFirstOption
            />
            {validationError?.email && (
              <p className="text-danger">Please select email(s)</p>
            )}
          </div>
          <div className="select_payment mb-2">
            <Row justify="space-between">
              <Col xs={24} lg={12}>
                {/* Email subject */}
                <div className="form-group">
                  <label>Email Subject</label>
                  <Input
                    className="inputcrm"
                    type="email"
                    placeholder={`${
                      isPayment ? "Payment" : "Receipt"
                    } From Outdoor Excellence Landscaping`}
                    size="large"
                    value={emailSubject}
                    onChange={handleEmailSubjectChange}
                  />
                  {validationError?.subject && (
                    <p className="text-danger">Please give email subject</p>
                  )}
                </div>
              </Col>
              <Col xs={24} lg={11}>
                {/* Email templates */}
                <div className="form-group">
                  <label>Email Template</label>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    defaultValue={emailCategory
                      ?.filter((obj) => obj?.defaultEnabled)
                      ?.map((obj) => ({
                        value: obj?._id,
                        label: obj?.name,
                      }))}
                    options={emailCategory?.map((obj) => ({
                      value: obj?._id,
                      label: obj?.name,
                    }))}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {/* <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload> */}
          <div className="form-group select_payment mb-2">
            <label>Attachments:</label>
            <Input
              type="file"
              className="radius-30 p-0"
              onChange={(e) => handleFileUpload(e)}
              multiple
              {...{
                ...((fileSizeError || validFileError) && { status: "error" }),
              }}
              ref={inputRef}
            />
            {!!files?.length &&
              files?.map((file, ind) => {
                const isValidFile = ATTACHMENT_TYPES.some((fileType) =>
                  file?.type?.includes(fileType),
                );
                return (
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className={`m-0 ${!isValidFile ? "text-danger" : ""}`}>
                      {file?.name}
                    </p>
                    <span
                      aria-hidden
                      onClick={() => handleRemoveFile(ind)}
                      className="text-danger"
                      style={{ cursor: "pointer" }}
                    >
                      x
                    </span>
                  </div>
                );
              })}
            <p className="m-0 text-secondary">
              Accepted files images, texts, and pdf(s)
            </p>
            {fileSizeError && (
              <p className="text-danger m-0">
                Can&apos;t upload more than 25MB
              </p>
            )}
            {validFileError && (
              <p className="text-danger m-0">Invalid file uploaded</p>
            )}
          </div>
          {/* Email template */}
          {bodyLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                minHeight: "100vh",
              }}
            >
              <SmallLoader />
            </div>
          ) : (
            <>
              <div
                contentEditable
                id="editable-content"
                className="email-template-editable-container"
                dangerouslySetInnerHTML={{ __html: emailTemplateDetails?.body }}
                onChange={handleEmailTemplateChange}
                onChangeCapture={(e) => console.log("e", e)}
                onKeyUp={() => handleEmailTemplateChange("editable-content")}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SendCustomerModal;
