import classNames from "classnames";

import {
  passwordLengthValidation,
  validatePassCond,
} from "../../../utils/javascript";
import { formatDate } from "../../setting/UserListV2";

export const filterStatus = {
  notSignedUp: "Not Signed Up",
  active: "Active",
  canceled: "Canceled",
  canceledOnTrial: "Canceled On Trial",
  activeOnTrial: "Active On Trial",
  onHold: "On Hold",
  rejected: "Rejected",
};

export const convertObjArray = (obj) =>
  Object.entries(obj).map(([value, label]) => ({ value, label }));

export const convertLinksCode = (value) =>
  value?.includes("https://") || value?.includes("http://")
    ? value?.slice(value?.lastIndexOf("//") + 2)
    : value;

export const currentPlan = [
  {
    value: "estimating",
    label: "Estimating (M)",
  },
  {
    value: "estimatingYearly",
    label: "Estimating (Y)",
  },
  {
    value: "essential",
    label: "Essential (M)",
  },
  {
    value: "essentialYearly",
    label: "Essential (Y)",
  },
];

export const currentComm = [
  {
    value: 3,
    label: "3%",
  },
  {
    value: 5,
    label: "5%",
  },
];

export const convertToFloating = (number) =>
  (Math.floor(number * 100) / 100).toFixed(2);

const referralActivities = {
  canceled: "Canceled",
  active: "Successful Payment",
  reactive: "Reactivation",
  onHold: "On Hold",
  rejected: "Rejected",
  signUp: "Signed Up For Free Trial",
  performedDemo: "Performed Demo",
  scheduledDemo: "Booked Demo",
  filledLeadForm: "Lead Forms",
  canceledOnTrial: "Canceled",
};
const activityColor = {
  canceled: "#E42626",
  active: "#22821A",
  reactive: "#4187D9",
  onHold: "#C21B1B",
  rejected: "#C21B1B",
  signUp: "#1F4495",
  performedDemo: "#A742D7",
  scheduledDemo: "#101828",
  filledLeadForm: "#101828",
  canceledOnTrial: "#E42626",
};

export const subscriptionPlanName = {
  estimating: "Estimating (M)",
  essential: "Essential (M)",
  estimatingYearly: "Estimating (A)",
  essentialYearly: "Essential (A)",
};

export const referralDetailCols = [
  {
    title: "Date",
    dataIndex: "date",
    render: (v) => `${formatDate(v)}`,
    className: "text-center",
  },
  {
    title: "Activity",
    dataIndex: "action",
    render: (v) => (
      <span style={{ color: activityColor[v] }}>{referralActivities[v]}</span>
    ),
    className: "text-center",
  },
  {
    title: "Plan",
    dataIndex: "subscriptionPlanName",
    render: (v) => (v ? subscriptionPlanName?.[v] : "-"),
    className: "text-center",
  },
  {
    title: "Total Price",
    dataIndex: "totalPrice",
    render: (v) => (v ? `$${convertToFloating(v)}` : "-"),
    className: "text-center",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    render: (v) => (v ? `$${convertToFloating(v)}` : "-"),
    className: "text-center",
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    render: (v) => (v ? `$${convertToFloating(v)}` : "-"),
    className: "text-center",
  },
  {
    title: "Commission",
    dataIndex: "commission",
    className: "text-center",
    render: (v) => (v ? `$${convertToFloating(v)}` : "-"),
  },
  {
    title: "Total Commission",
    dataIndex: "totalCommission",
    className: "text-center",
    render: (v, record) =>
      v ? (
        <span
          className={classNames({
            "successful-payment-text": record?.activity === "successfulPayment",
          })}
        >{`$${convertToFloating(v)}`}</span>
      ) : (
        "-"
      ),
  },
];

export const mockData = [
  {
    date: "10/25/23",
    activity: "canceled",
    currentPlan: "Estimating (M)",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "8.5053",
  },
  {
    date: "10/25/23",
    activity: "successfulPayment",
    currentPlan: "Estimating (M)",
    totalPrice: "97.998658",
    discount: "",
    amountPaid: "97.998658",
    commission: "2.3415246",
    totalCommission: "5.572864",
  },
  {
    date: "10/25/23",
    activity: "reactivation",
    currentPlan: "Estimating (M)",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "",
  },
  {
    date: "9/25/23",
    activity: "onHold",
    currentPlan: "Estimating (M)",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "2.64532185",
  },
  {
    date: "8/25/23",
    activity: "rejected",
    currentPlan: "Estimating (M)",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "2.64532185",
  },
  {
    date: "7/25/23",
    activity: "signUpTrial",
    currentPlan: "Estimating (M)",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "",
  },
  {
    date: "7/8/23",
    activity: "performedDemo",
    currentPlan: "",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "",
  },
  {
    date: "7/1/23",
    activity: "scheduledDemo",
    currentPlan: "",
    totalPrice: "",
    discount: "",
    amountPaid: "",
    commission: "",
    totalCommission: "",
  },
];

export const modifyAffiliateStatus = {
  Rejected: "Declined",
};

export const passwordValidator = (value) => {
  if (!value) {
    return Promise.reject(passwordLengthValidation());
  }
  if (value && validatePassCond(value)) {
    return Promise.reject(validatePassCond(value));
  }
  if (value && !validatePassCond(value)) return Promise.resolve();
};

export const discountTypes = {
  percentage: "Percentage",
  freeTrial: "Free Trial",
  amount: "Fixed Amount",
};

export const formatSubscriptionTypes = (subscriptionTypes) => {
  const formattedTypes = subscriptionTypes.map((type) =>
    type.replace(" subscription", ""),
  );

  if (formattedTypes.length === 1) {
    return formattedTypes[0];
  }
  if (formattedTypes.length === 2) {
    return `${formattedTypes[0]} & ${formattedTypes[1]}`;
  }
  if (formattedTypes.length > 2) {
    const lastType = formattedTypes.pop();
    const joinedTypes = formattedTypes.join(", ");
    return `${joinedTypes} & ${lastType}`;
  }
  return "";
};

export const paymentStatus = {
  requested: "Requested",
  paid: "Paid",
  error: "Error",
};
export const paymentStatusColor = {
  requested: "#FF9045",
  paid: "#719D40",
  error: "#E42626",
};

export const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }
  return value === undefined || value === null || value === "";
};
