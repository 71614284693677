export const NumberFormat = (value, rest) => {
  const { fractionalPoint = false, roundOffDigits } = rest || {};
  if (!value && fractionalPoint) {
    return "$0.00";
  }
  if (!value) {
    return "$0";
  }
  /**
   * These options enable the currency symbol and thousand separators
   */
  const options = {
    style: "currency",
    currency: "USD",
  };
  /**
   * If roundOff is true then value should be rounded off.
   * maximumFractionDigits: 0 means this will print as $2,501
   * minimumFractionDigits: 0 means this will print as $2,501.55
   */
  if (roundOffDigits !== undefined) {
    options.maximumFractionDigits = 0;
  } else {
    const roundOff = localStorage.getItem("roundOff");
    if (JSON.parse(roundOff)) {
      options.maximumFractionDigits = 0;
    } else {
      options.minimumFractionDigits = 2;
    }
  }
  const updatedValue = new Intl.NumberFormat("en-US", options).format(value);
  return updatedValue;
};

export const convertToOriginalNumber = (value) =>
  +value?.replace(/[^0-9.,]/g, "");
