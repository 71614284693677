import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../../api/api";
import {
  generateQBPaymentLink,
  generateSquarePaymentLink,
  generateStripePaymentLink,
  getSquareCharge,
} from "../../api/invoice";
import ModalDailog from "../../common/components/ModalDialogue";
import defaultCoverImg from "../../images/defaultCoverPhoto.jpg";
import logo from "../../images/logo-invoice.png";
import {
  getSquareInfoAction,
  modalToggleAction,
  setReceiptData,
  updateInvoiceReceiptDetailAction,
} from "../../redux/invoice/invoice.action";
import { createValidUrl } from "../../utils/commonFuctions";
import { DATE_FORMATE, PAYMENT_STATUS } from "../../utils/constants/constants";
import { NumberFormat } from "../../utils/numberFormat";
import regex from "../../utils/regex";
import {
  addExtraPercentageAmount,
  downloadBase64File,
  getBase64OfHTMLDivComponentAsPDF,
} from "../contractpreview/v3/helper";
import {
  ACHPaymentPopup,
  CardPaymentFeePopup,
  CreditCardPaymentPopup,
  PayDepositePopup,
  PayWithCheckCash,
  QBBillingDetail,
  SquareAchPaymentModal,
} from "../contractpreview/v3/Modal";
import SmallLoader from "../loader/smallLoader";
import {
  extractToken,
  receiptColumn,
  renderStatus,
  renderTableAndFooter,
} from "./helper";
import ViewReceipt from "./ViewReceipt";
const ViewInvoiceDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [token] = useState(new URLSearchParams(location.search).get("token"));
  const [paymentStatus] = useState(
    new URLSearchParams(location.search).get("payment"),
  );
  const [paymentText, setPaymentText] = useState("Pay Invoice");
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendReceiptDetails = useSelector(
    (state) => state?.invoice?.sendReceipt,
  );
  const { modalToggle, currentIndex, squarePaymentInfo } = useSelector(
    (state) => state?.invoice,
  );
  const [viewed, setViewed] = useState(false);
  const [callingView, setCallingView] = useState(false);
  const isEstimationViewed = localStorage.getItem(
    sendReceiptDetails?.invoiceList?.[0]?._id,
  );
  const [prevPopup, setPrevPopup] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingError, setBillingError] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const getReceiptData = async (token) => {
    setLoading(true);
    const response = await api.request({
      url: `v3/invoice/customer/view-invoice?token=${token}`,
      method: "GET",
    });
    if (response.remote === "success") {
      dispatch(setReceiptData(response.data.data));
      if (
        response.data.data?.invoiceList?.[currentIndex]
          ?.displayPaymentButton === "square"
      )
        dispatch(
          getSquareInfoAction(extractToken(response.data.data?.shareableLink)),
        );
    } else {
      navigate("/receipt-not-found", {
        state: {
          data: response.errors.errors.data,
        },
      });
    }
    setLoading(false);
  };

  const receiptDataRow = sendReceiptDetails?.invoiceList?.map((obj) => ({
    description: obj?.invoiceName,
    invoice: obj?.invoiceNumber,
    status: renderStatus(obj?.invoiceStatus),
    amount: NumberFormat(obj?.invoiceAmount, { fractionalPoint: true }),
    due_date: moment(obj?.dueDate).format(DATE_FORMATE),
    amount_paid: NumberFormat(obj?.amountPaid, { fractionalPoint: true }),
    date_paid: obj?.amountPaidDate
      ? moment(obj?.amountPaidDate).format(DATE_FORMATE)
      : "-",
    balance: obj?.balance
      ? NumberFormat(obj?.balance)
      : NumberFormat(obj?.invoiceAmount - obj?.amountPaid, {
          fractionalPoint: true,
        }),
  }));

  const tableData = {
    invoice: { column: receiptColumn, data: receiptDataRow },
  };

  const openPopups = () => {
    const invoice = sendReceiptDetails?.invoiceList?.[currentIndex];
    const isPaymentIntegrate =
      sendReceiptDetails?.invoiceList?.[currentIndex]?.displayPaymentButton;
    if (
      invoice?.allowCardPayment &&
      invoice?.allowAchPayment &&
      isPaymentIntegrate
    ) {
      dispatch(
        modalToggleAction({
          selectPaymentMethod: true,
        }),
      );
    }
    if (
      invoice?.allowCardPayment &&
      !invoice?.allowAchPayment &&
      isPaymentIntegrate
    ) {
      dispatch(
        modalToggleAction({
          payWithCard: true,
        }),
      );
    }
    if (
      !invoice?.allowCardPayment &&
      invoice?.allowAchPayment &&
      isPaymentIntegrate
    ) {
      dispatch(
        modalToggleAction({
          achPayment: true,
        }),
      );
    }
    if (!isPaymentIntegrate) {
      dispatch(
        modalToggleAction({
          payWithCheckCash: true,
        }),
      );
    }
  };

  const handleCancelPayWithCash = () => {
    dispatch(
      modalToggleAction({
        payWithCheckCash: false,
      }),
    );
  };

  const handleStripePayment = async (type) => {
    setPaymentLoading(true);
    const response = await generateStripePaymentLink(token, type);
    if (response.remote === "success") {
      dispatch(
        modalToggleAction({
          selectPaymentMethod: false,
        }),
      );
      window.location.href = response.data.data.paymentLink;
    } else {
      message.error(response?.errors?.errors?.error);
    }
    setPaymentLoading(false);
  };

  const handleCancelQBCard = () => {
    dispatch(
      modalToggleAction({
        qbBillingDetails: false,
      }),
    );
    setBillingEmail("");
    setBillingError("");
  };

  const handleBillingEmailChange = (e) => {
    const { value } = e?.target;
    setBillingEmail(value);
    setBillingError(
      !value
        ? "Please enter billing email"
        : !regex.emailRegex.test(value)
        ? "Billing email is invalid"
        : "",
    );
  };

  const openLinkNewTab = (url) => {
    try {
      const a = document.getElementById("open-new-tab");
      a.setAttribute("href", url);
      console.log("first #401", a);
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      a.dispatchEvent(clickEvent);
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleQBPayment = async ({ type }) => {
    const token = extractToken(sendReceiptDetails?.shareableLink);
    const invoiceId = sendReceiptDetails?.invoiceList?.[currentIndex]?._id;
    setBtnLoading(true);
    if (billingEmail) {
      const response = await generateQBPaymentLink({
        token,
        type,
        invoiceId,
        billingEmail,
      });
      if (response.remote === "success") {
        if (response?.data?.data?.link) {
          openLinkNewTab(response.data.data.link);
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        setBillingEmail("");
        setBillingError("");
        if (type === "CARD") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        if (type === "ACH") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        setBtnLoading(false);

        // if (window) window.open(response.data.data.link, "_blank").focus();
        // window.location.href = response.data.data.link;
      } else {
        message.error(response?.errors?.errors?.error);
        setBtnLoading(false);
      }
    } else {
      setBillingError("Please enter billing email");
      setBtnLoading(false);
    }
  };

  const handleConfirmCardPayment = async () => {
    const paymentMethod =
      sendReceiptDetails?.invoiceList?.[currentIndex]?.displayPaymentButton;
    const token = extractToken(sendReceiptDetails?.shareableLink);
    const invoiceId = sendReceiptDetails?.invoiceList?.[currentIndex]?._id;
    console.log("paymentMethod", paymentMethod);
    if (paymentMethod === "quickbooks") {
      if (token) {
        setPaymentType("CARD");
        dispatch(
          modalToggleAction({
            qbBillingDetails: true,
          }),
        );
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "stripe") {
      if (token) {
        const response = await generateStripePaymentLink(
          token,
          "CARD",
          invoiceId,
        );
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          dispatch(
            modalToggleAction({
              cardFeePayment: false,
            }),
          );
        } else {
          message.error(response?.errors?.errors?.error);
        }
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "square") {
      if (token) {
        const response = await generateSquarePaymentLink({
          token,
          type: "CARD",
          invoiceId,
        });
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          dispatch(
            modalToggleAction({
              cardFeePayment: false,
            }),
          );
        }
      } else {
        message.error("Token not provided");
      }
    }
  };

  const handleCardPaymentCancel = ({ prevPopup }) => {
    setPrevPopup(prevPopup);
    if (sendReceiptDetails?.invoiceList?.[currentIndex]?.chargeEnabled) {
      dispatch(
        modalToggleAction({
          cardFeePayment: true,
        }),
      );
    } else {
      handleConfirmCardPayment();
    }
  };

  const handleACHPaymentCancel = async () => {
    const paymentMethod =
      sendReceiptDetails?.invoiceList?.[currentIndex]?.displayPaymentButton;
    const token = extractToken(sendReceiptDetails?.shareableLink);
    const invoiceId = sendReceiptDetails?.invoiceList?.[currentIndex]?._id;
    setPaymentType("");
    if (paymentMethod === "quickbooks") {
      setPaymentType("ACH");
      dispatch(
        modalToggleAction({
          qbBillingDetails: true,
        }),
      );
    }
    if (paymentMethod === "stripe") {
      const response = await generateStripePaymentLink(token, "ACH", invoiceId);
      if (response.remote === "success") {
        if (response?.data?.data?.paymentLink) {
          window.location.href = response.data.data.paymentLink;
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        dispatch(
          modalToggleAction({
            achPayment: false,
          }),
        );
      } else {
        message.error(response?.errors?.errors?.error);
      }
    }
    if (paymentMethod === "square") {
      dispatch(
        modalToggleAction({
          squareACHPayment: true,
        }),
      );
    }
  };

  const handleCancelSquareAch = () => {
    dispatch(
      modalToggleAction({
        squareACHPayment: false,
      }),
    );
  };

  const handleTokenizeResponse = async (token) => {
    const authToken = extractToken(sendReceiptDetails?.shareableLink);
    const data = {
      paymentToken: token?.token,
    };
    const response = await getSquareCharge(authToken, data);
    if (response.remote === "success") {
      message.success(
        "Payment made successful, please wait for the bank to confirm the payment",
      );
      dispatch(
        updateInvoiceReceiptDetailAction(
          sendReceiptDetails?.invoiceList?.[currentIndex]?._id,
          response?.data?.data,
        ),
      );
      dispatch(
        modalToggleAction({
          squareACHPayment: false,
        }),
      );
      setPaymentLoading(false);
    }
  };

  const handleBackToCard = () => {
    if (prevPopup === "card") {
      dispatch(
        modalToggleAction({
          payWithCard: true,
        }),
      );
    }
    if (prevPopup === "options") {
      dispatch(
        modalToggleAction({
          selectPaymentMethod: true,
        }),
      );
    }
  };

  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    setTimeout(async () => {
      const base64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      downloadBase64File(
        base64,
        `${sendReceiptDetails?.invoiceList?.[0]?.billedToName} - Invoice - ${sendReceiptDetails?.invoiceList?.[0]?.invoiceNumber}.pdf`,
      );
      setGeneratingPdf(false);
    }, 1000);
  };
  const getPaymentStatus = async () => {
    setPaymentLoading(true);
    const response = await api.request({
      url: `v3/invoice/customer/payment-status?token=${token}`,
      method: "GET",
    });
    if (response.remote === "success") {
      if (response.data.data.status === PAYMENT_STATUS.PENDING) {
        setPaymentText("Verifying your payment. Please wait...");
        setTimeout(getPaymentStatus, 5000);
      } else if (response.data.data.status === PAYMENT_STATUS.FAILED) {
        setPaymentText("Pay Invoice");
      } else {
        setPaymentText("Success");
        navigate(`/view-invoice/?token=${token}`);
      }
    }
    setPaymentLoading(false);
  };

  useEffect(() => {
    if (token) {
      getReceiptData(token);
    }
  }, [token]);

  useEffect(() => {
    if (
      paymentStatus !== PAYMENT_STATUS.PENDING ||
      sendReceiptDetails?.invoiceList?.[0]?.paymentStatus !==
        PAYMENT_STATUS.PENDING
    ) {
      getPaymentStatus();
    }
  }, [paymentStatus, sendReceiptDetails?.invoiceList]);
  useEffect(() => {
    if (
      sendReceiptDetails?.invoiceList?.[0]?._id &&
      !viewed &&
      !isEstimationViewed
    ) {
      setViewed(true);
    }
  }, [sendReceiptDetails]);

  useEffect(() => {
    let paymentStatusInterval = null;
    if (paymentLoading) {
      paymentStatusInterval = setInterval(() => {
        getPaymentStatus();
      }, 5000);
    }
    return () => {
      clearInterval(paymentStatusInterval);
    };
  }, []);

  return (
    <>
      <a
        style={{ display: "none" }}
        id="open-new-tab"
        href="#!"
        target="_blank"
        rel="noreferrer"
      >
        click
      </a>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
          }}
        >
          <SmallLoader />
        </div>
      ) : (
        <Row justify="center" className="mt-3 mx-973">
          <Col xs={24} lg={24}>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                type="primary"
                onClick={handleDownloadPdf}
                disabled={generatingPdf}
                className="my-2 download-pdf-btn"
                icon={
                  <>
                    {generatingPdf && <SmallLoader />}
                    <DownloadOutlined />
                  </>
                }
              >
                Download
              </Button>
            </div>
            <div className="mt-3 mb-3" id="divToPrint">
              {sendReceiptDetails?.invoiceList?.map((obj, ind) => (
                <>
                  <Card bodyStyle={{ padding: "10px" }} className="mt-3">
                    {obj?.organizationCoverPhoto ? (
                      <img
                        alt=""
                        src={
                          obj?.organizationCoverPhoto
                            ? createValidUrl(obj?.organizationCoverPhoto)
                            : defaultCoverImg
                        }
                        className="rounded-3 w-100"
                      />
                    ) : (
                      ""
                    )}

                    <div className="px-4 pt-4">
                      <img
                        alt={`organization-logo-${ind}`}
                        src={
                          obj?.organizationLogo
                            ? createValidUrl(obj?.organizationLogo)
                            : logo
                        }
                        style={{ width: "155px", height: "74px" }}
                      />
                    </div>
                    <div className="mt-3 p-4 d-flex align-items-start justify-content-between">
                      <ul className="list-style">
                        <li>
                          <span className="font-16">
                            {obj?.organizationName}
                          </span>
                        </li>
                        {obj?.organizationPhoneNumber && (
                          <li>{`${formatPhoneNumber(
                            `+${obj?.organizationPhoneNumber || ""}`,
                            "NATIONAL",
                          )}`}</li>
                        )}
                        <li>{obj?.organizationEmail}</li>
                        <li>{obj?.organizationAddress}</li>
                      </ul>
                      <ul className="list-style text-right">
                        <li>
                          Invoice Number <br /> {obj?.invoiceNumber}
                        </li>

                        <li className="mt-2">
                          Invoice Date <br />{" "}
                          {moment(obj?.invoiceDate).format(DATE_FORMATE)}
                        </li>

                        <li className="mt-2">
                          Due Date <br />
                          {moment(obj?.dueDate).format(DATE_FORMATE)}
                        </li>
                      </ul>
                    </div>
                    <div className="p-4 d-flex align-items-start justify-content-between pt-0">
                      <ul className="list-style">
                        <li>
                          <span className="font-16">Billed To:</span>
                        </li>
                        {obj?.billedToPhoneNumber && (
                          <li>{`${formatPhoneNumber(
                            `+${obj?.billedToPhoneNumber || ""}`,
                            "NATIONAL",
                          )}`}</li>
                        )}
                        <li>
                          {obj?.billedToName} <br />
                          {obj?.billedToAddress}
                        </li>
                      </ul>
                      <ul className="list-style text-right">
                        <li>
                          Total Project <br />{" "}
                          {NumberFormat(obj?.totalProjectAmount)}
                        </li>

                        <li className="mt-2">
                          Project Balance <br />{" "}
                          {NumberFormat(
                            obj?.totalProjectAmount -
                              (obj?.totalPaidAmount || 0),
                            { fractionalPoint: true },
                          )}
                        </li>

                        <li className="mt-2">
                          Total Paid <br />
                          {NumberFormat(obj?.totalPaidAmount, {
                            fractionalPoint: true,
                          })}
                        </li>
                      </ul>
                    </div>
                    <div className="px-2 invoice-title mt-3">
                      {renderTableAndFooter({
                        redirectFrom: "invoice",
                        data: obj,
                        tableData: tableData.invoice,
                        handlePayment: openPopups,
                        paymentLoading,
                        paymentBtnText: paymentText,
                      })}
                    </div>
                  </Card>
                </>
              ))}
            </div>
          </Col>
        </Row>
      )}
      <ModalDailog isModalOpen={viewed} closeIcon={<></>}>
        <ViewReceipt
          receiptType="invoice"
          callingView={callingView}
          setViewed={setViewed}
          setCallingView={setCallingView}
        />
      </ModalDailog>
      <PayDepositePopup
        isModalVisible={modalToggle?.selectPaymentMethod}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleACHPaymentCancel={handleACHPaymentCancel}
        paymentIntegration={
          sendReceiptDetails?.invoiceList?.[0]?.displayPaymentButton
        }
        handleCancel={() =>
          dispatch(
            modalToggleAction({
              selectPaymentMethod: false,
            }),
          )
        }
        setIsModalVisible={() => {
          dispatch(
            modalToggleAction({
              payWithCheckCash: true,
            }),
          );
        }}
      />
      <CreditCardPaymentPopup
        showCardPayment={modalToggle?.payWithCard}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleCancel={() =>
          dispatch(
            modalToggleAction({
              payWithCard: false,
            }),
          )
        }
        setIsModalVisible={() => {
          dispatch(
            modalToggleAction({
              payWithCheckCash: true,
            }),
          );
        }}
      />
      <CardPaymentFeePopup
        showCardPaymentFee={modalToggle?.cardFeePayment}
        cardPercentage={
          sendReceiptDetails?.invoiceList?.[currentIndex]?.chargePercentage
        }
        paymentAmount={addExtraPercentageAmount(
          sendReceiptDetails?.invoiceList?.[currentIndex]?.invoiceAmount,
          sendReceiptDetails?.invoiceList?.[currentIndex]?.chargePercentage,
        )}
        handleCardPaymentCancel={() => handleStripePayment("CARD")}
        handleConfirmCardPayment={handleConfirmCardPayment}
        handleBackToCard={handleBackToCard}
        handleCancel={() =>
          dispatch(
            modalToggleAction({
              cardFeePayment: false,
            }),
          )
        }
      />
      <ACHPaymentPopup
        showACHPayment={modalToggle?.achPayment}
        handleACHPaymentCancel={handleACHPaymentCancel}
        setIsModalVisible={() => {
          dispatch(
            modalToggleAction({
              payWithCheckCash: true,
            }),
          );
        }}
        handleCancel={() =>
          dispatch(
            modalToggleAction({
              achPayment: false,
            }),
          )
        }
      />
      <PayWithCheckCash
        depositPopupDetail={sendReceiptDetails?.invoiceList?.[0]}
        handleCancel={handleCancelPayWithCash}
        handleClose={handleCancelPayWithCash}
        handleOk={handleCancelPayWithCash}
        isModalVisible={modalToggle?.payWithCheckCash}
        openPopups={openPopups}
      />
      <QBBillingDetail
        isModalOpen={modalToggle?.qbBillingDetails}
        handleCancel={handleCancelQBCard}
        billingEmail={billingEmail}
        handleBillingEmailChange={handleBillingEmailChange}
        billingError={billingError}
        type={paymentType}
        handleQBPayment={handleQBPayment}
        loading={btnLoading}
      />
      <SquareAchPaymentModal
        isModalOpen={modalToggle?.squareACHPayment}
        handleCancel={handleCancelSquareAch}
        locationId={squarePaymentInfo?.locationId}
        handleTokenizeResponse={handleTokenizeResponse}
        loading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
      />
    </>
  );
};

export default ViewInvoiceDetails;
