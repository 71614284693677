import { message } from "antd";

import api from "../../api/api";
import {
  getGraphData,
  getSquareCharge,
  getSquareLocationId,
} from "../../api/invoice";
import {
  createFilterData,
  createRows,
  handleStatusColor,
} from "../../components/invoiceV3/invoiceDashboard/tableHelper";
import store from "../../store";
import { getSectionFromUrl } from "../../utils/constants/constants";
import { INVOICE_TYPE } from "./invoice.types";
export const getEstimationChangeOrder = (estimationId) => async (dispatch) => {
  dispatch(setInvoiceLoading());
  const response = await api.request({
    url: `v3/user-estimate/${estimationId}/summary`,
    method: "GET",
  });

  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.ESTIMATION_CHANGE_ORDER,
      payload: response.data.data,
    });
    dispatch(setInvoiceLoading());
  } else dispatch(setInvoiceLoading());
};
export const getPendingEstimationChangeOrder =
  (estimationId) => async (dispatch) => {
    dispatch(setInvoiceLoading());
    const response = await api.request({
      url: `v3/user-estimate/${estimationId}/pending/summary`,
      method: "GET",
    });

    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.ESTIMATION_CHANGE_ORDER_PENDING,
        payload: response.data.data.services,
      });
      dispatch(setInvoiceLoading());
    } else dispatch(setInvoiceLoading());
  };

export const setInvoiceLoading = () => async (dispatch, getState) => {
  const loading = getState()?.invoice?.loading;
  dispatch({
    type: INVOICE_TYPE.LOADING,
    payload: !loading,
  });
};

export const getInvoiceList =
  (estimationId, needLoading = true, type = "") =>
  async (dispatch) => {
    if (needLoading) dispatch(setInvoiceLoading());
    const response = await api.request({
      url: `v3/invoice?estimationId=${estimationId}${
        type ? `&invoicesType=${type}` : ""
      }`,
      method: "GET",
    });

    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.INVOICE_LIST,
        payload: response.data.data,
      });
      if (needLoading) dispatch(setInvoiceLoading());
    } else {
      if (needLoading) dispatch(setInvoiceLoading());
    }
  };

export const updateInvoiceData =
  (id, key, data) => async (dispatch, getState) => {
    const invoiceList = getState()?.invoice?.invoiceList;
    const dataIndex = invoiceList?.findIndex((obj) => obj?._id === id);
    if (~dataIndex) {
      invoiceList[dataIndex] = {
        ...invoiceList[dataIndex],
        ...(typeof data === "object"
          ? Array.isArray(data)
            ? [...data]
            : { ...data }
          : { [key]: data }),
      };
    }
    dispatch({
      type: INVOICE_TYPE.INVOICE_LIST,
      payload: invoiceList,
    });
  };

export const setReceiptData = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SEND_RECEIPT,
    payload: { ...data },
  });
};

export const showBannerAction =
  (data, needWarning = false) =>
  async (dispatch) => {
    dispatch({
      type: INVOICE_TYPE.SHOW_BANNER,
      payload: { ...data },
    });
    if (needWarning) {
      dispatch(showWarningBannerAction(true));
    }
  };

export const showWarningBannerAction = (toggle) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SHOW_WARNING_BANNER,
    payload: toggle,
  });
};

export const deleteInvoiceAction = (rowId) => async (dispatch, getState) => {
  const invoiceList = getState()?.invoice?.invoiceList;
  const updatedList = invoiceList?.filter((obj) => obj?._id !== rowId);
  dispatch({
    type: INVOICE_TYPE.INVOICE_LIST,
    payload: updatedList,
  });
};

export const updateInvoiceNoteAction =
  (note, id) => async (dispatch, getState) => {
    const invoiceReceipt = getState()?.invoice?.sendReceipt;
    const ind = invoiceReceipt?.invoiceList?.findIndex(
      (obj) => obj?._id === id,
    );
    if (~ind && invoiceReceipt?.invoiceList) {
      invoiceReceipt.invoiceList[ind] = {
        ...invoiceReceipt?.invoiceList?.[ind],
        invoiceNote: note,
      };
      dispatch(setReceiptData({ ...invoiceReceipt }));
    }
  };

export const getEmailTemplatesAction = (emailCategory) => async (dispatch) => {
  const response = await api.request({
    url: `v3/settings/email-templates/list?category=${emailCategory}`,
    method: "GET",
  });
  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.INVOICE_EMAIL_CATEGORY,
      payload: response.data.data,
    });
  }
};
export const getSummaryDetails = (id) => async (dispatch) => {
  const response = await api.request({
    url: `v3/invoice/project-summary?estimationId=${id}`,
    method: "GET",
  });

  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.SUMMARY_DETAILS,
      payload: response.data.data,
    });
  }
};
export const getProjectSummaryDetails = (estimationId) => async (dispatch) => {
  const response = await api.request({
    url: `v3/invoice/summary?estimationId=${estimationId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.PROJECT_DETAILS,
      payload: response.data.data,
    });
  }
};

export const resetSendReceiptAction = () => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SEND_RECEIPT,
    payload: {},
  });
};

export const receiptEmailDetails = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.RECEIPT_DETAILS,
    payload: data,
  });
};

export const addCustomOptionsActions =
  (value) => async (dispatch, getState) => {
    let data = getState()?.invoice?.receiptDetails;
    if (!data?.suggestedEmails) {
      data = {
        ...data,
        suggestedEmails: [value],
      };
    }
    if (data?.suggestedEmails && !data?.suggestedEmails?.includes(value)) {
      data?.suggestedEmails?.push(value);
    }
    dispatch({
      type: INVOICE_TYPE.RECEIPT_DETAILS,
      payload: data,
    });
  };

export const modalToggleAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.MODAL_TOGGLE,
    payload: data,
  });
};

export const getTagsDetails = (estId) => async (dispatch) => {
  const response = await api.request({
    url: `v3/settings/email-templates/tags-value?estimationId=${estId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.TAGS,
      payload: response.data.data,
    });
  }
};

export const setEditRecordPaymentID = (id) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SET_EDIT_PAYMENT_ID,
    payload: id,
  });
};

export const editInvoiceAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.EDIT_INVOICE_ROW,
    payload: data,
  });
};
export const addInvoiceAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.ADD_INVOICE_ROW,
    payload: data,
  });
};

export const setUnpaidTotalSummary = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SET_UNPAID_SUMMARY,
    payload: data,
  });
};

export const setRecordInvoicePayment =
  (name, value, ind = 0) =>
  async (dispatch, getState) => {
    const invoiceState = getState()?.invoice;
    const recordPayment = invoiceState?.recordPayment?.map((obj) => ({
      ...obj,
    }));
    recordPayment[ind] = {
      ...recordPayment[ind],
      [name]: value,
    };
    dispatch({
      type: INVOICE_TYPE.SET_RECORD_PAYMENT,
      payload: [...recordPayment],
    });
  };

export const editRecordPaymentsAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.EDIT_RECORD_PAYMENT,
    payload: data,
  });
};

export const clearRecordPayment = () => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.CLEAR_RECORD_PAYMENT,
    payload: [],
  });
};

export const disablePaginationAction = (toggle) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.DISABLED_PAGINATION,
    payload: toggle,
  });
};

export const currentIndexAction = (currentIndex) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.CURRENT_INDEX,
    payload: currentIndex,
  });
};

export const isEditRecordPaymentAction = (toggle) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.IS_EDIT_RECORD_PAYMENT,
    payload: toggle,
  });
};

export const internalNoteAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.INTERNAL_NOTE,
    payload: data,
  });
};

export const deleteResAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.DELETE_INVOICE_REASON,
    payload: data,
  });
};

export const updateInvoiceListAction = (data) => async (dispatch, getState) => {
  const invoiceList = getState()?.invoice?.invoiceList;
  invoiceList.forEach((invoice) => {
    const matchingData = data.find((item) => item._id === invoice._id);
    if (matchingData) {
      Object.assign(invoice, matchingData);
    }
  });
  dispatch({
    type: INVOICE_TYPE.INVOICE_LIST,
    payload: [...invoiceList],
  });
};

export const addNewInvoiceRow = (row) => async (dispatch, getState) => {
  const invoiceList = getState()?.invoice?.invoiceList;
  invoiceList?.push(row);
  dispatch({
    type: INVOICE_TYPE.INVOICE_LIST,
    payload: [...invoiceList],
  });
};

export const getPaymentList =
  (estimationId, needLoading = false) =>
  async (dispatch) => {
    if (needLoading) dispatch(setInvoiceLoading());
    const response = await api.request({
      url: `v3/invoice/payment?estimationId=${estimationId}`,
      method: "GET",
    });

    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.PAYMENT_LIST,
        payload: response.data.data,
      });
      if (needLoading) dispatch(setInvoiceLoading());
    } else {
      if (needLoading) dispatch(setInvoiceLoading());
    }
  };

export const getInvoiceAndPaymentList = (estimationId) => async (dispatch) => {
  dispatch(getInvoiceList(estimationId));
  dispatch(getPaymentList(estimationId));
  dispatch(getSummaryDetails(estimationId));
};
export const getProjectStatuses = () => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_TYPE.SET_LOADING_PROJECT_STATUSES,
      payload: true,
    });

    const response = await api.request({
      url: "/v3/invoice/dashboard/project-status",
      method: "GET",
    });

    if (response.remote === "success") {
      const transformedData = response.data.data.map((status) => ({
        label: status.name,
        value: status.StatusId,
        color: status?.color || handleStatusColor(status.name),
      }));

      dispatch({
        type: INVOICE_TYPE.PROJECT_STATUSES,
        payload: transformedData,
      });
    }
  } catch (error) {
    console.error("Failed to fetch project statuses:", error);
  } finally {
    dispatch({
      type: INVOICE_TYPE.SET_LOADING_PROJECT_STATUSES,
      payload: false,
    });
  }
};
export const fetchTabsData = (navigate) => async (dispatch) => {
  const section = getSectionFromUrl();
  try {
    const response = await api.request({
      url: "/v3/invoice/dashboard/tab-filters",
      method: "GET",
    });

    if (response.remote === "success") {
      const selectedId =
        section ||
        response?.data?.data?.find((obj) => obj?.name === "All Invoices")?._id;
      const tab = response?.data?.data?.find((item) => item._id === selectedId);
      console.log(
        "Comment the code I guess its not needed: Ralph - invoice.action line #426",
      );
      /* dispatch(
        setSelectedFilters({
          ...selectedFilters[tab?._id],
          selectedTab: tab?._id,
          invoiceStatuses: tab?.filterObject?.selectedInvoicesStatus,
          projectStatuses: projectStatuses
            .filter((status) =>
              tab?.filterObject?.selectedProjectStatus?.includes(status.label),
            )
            .map((status) => status.value),
        }),
      ); */
      navigate(`/invoice-dashboard?section=${tab?._id}`);
      dispatch(setTabsData(response?.data?.data));
    }
  } catch (error) {
    console.error("Failed to fetch tabs data:", error);
  }
};

// Action to set selected filters with dynamic section key
export const setSelectedFilters = (filters) => {
  const section = getSectionFromUrl();
  return {
    type: INVOICE_TYPE.SET_SELECTED_FILTERS,
    payload: { section, filters },
  };
};

export const setTabsData = (tabs) => ({
  type: INVOICE_TYPE.SET_TABS_DATA,
  payload: tabs,
});
export const addOrUpdateTab = (tabData) => async (dispatch) => {
  const action = tabData.isTabEdit ? "Updating" : "Adding";
  const successAction = tabData.isTabEdit ? "updated" : "added";
  const loadingMessage = message.loading(`${action} tab...`, 0);

  if (tabData.isTabEdit) {
    tabData.tabFilterId = tabData.selectedTabId;
  }

  const response = await api.request({
    url: "/v3/invoice/dashboard/tab-filters",
    method: "POST",
    data: tabData,
  });

  loadingMessage();
  if (response.remote === "success") {
    if (tabData.isTabEdit) {
      dispatch({
        type: INVOICE_TYPE.CREATE_TAB_FILTER_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: INVOICE_TYPE.ADD_TAB_SUCCESS,
        payload: response.data.data,
      });
    }

    message.success(`Tab ${successAction} successfully.`);
  } else {
    message.error(
      response.errors?.errors?.error ||
        `Failed to ${action.toLowerCase()} the tab.`,
    );
  }
};

export const deleteTab = (tabId, navigate) => async (dispatch) => {
  const { tabs, selectedFilters } = store.getState().invoice;
  try {
    const loadingMessage = message.loading("Deleting tab...", 0);

    const response = await api.request({
      url: `/v3/invoice/dashboard/tab-filters/${tabId}`,
      method: "DELETE",
    });

    loadingMessage();

    if (response.remote === "success") {
      const newTab = tabs.find((item) => item._id !== tabId);
      dispatch({
        type: INVOICE_TYPE.DELETE_TAB_SUCCESS,
        payload: tabId,
      });
      dispatch(
        setSelectedFilters({
          ...selectedFilters[newTab._id],
          selectedTab: newTab._id,
        }),
      );
      navigate(`/invoice-dashboard?section=${newTab._id}`);
      message.success("Tab deleted successfully.");
    } else {
      message.error("Failed to delete tab.");
    }
  } catch (error) {
    // Handle error case
    console.error("Failed to delete tab:", error);
    // Show error message to the user
    message.error(
      "An error occurred while deleting the tab. Please try again later.",
    );
  }
};
export const fetchTableData = () => async (dispatch) => {
  const { selectedFilters, projectStatuses } = store.getState().invoice;
  const section = getSectionFromUrl();
  dispatch({
    type: INVOICE_TYPE.SET_LOADING,
    payload: {
      section,
      tableLoading: true,
    },
  });
  const filterData = createFilterData(
    selectedFilters[section] || selectedFilters.null,
  );
  const response = await api.request({
    url: "/v3/invoice/dashboard/list",
    method: "POST",
    data: filterData,
  });
  if (response.remote === "success") {
    const row = createRows(response.data?.data?.data, projectStatuses);
    dispatch({
      type: INVOICE_TYPE.SET_TABLE_INVOICES,
      payload: response.data.data,
    });
    dispatch(
      setSelectedFilters({
        ...selectedFilters[section],
        modifiedTableInvoices: row,
      }),
    );
  } else {
  }
  dispatch({
    type: INVOICE_TYPE.SET_LOADING,
    payload: {
      section,
      tableLoading: false,
    },
  });
};
export const createTabFilter = (tabData) => async (dispatch) => {
  const { projectStatuses } = store.getState().invoice;
  try {
    const loadingMessage = message.loading("Creating tab filter...", 0);
    const payload = {
      ...tabData,
      filterObject: {
        ...tabData.filterObject,
        selectedProjectStatus: projectStatuses
          .filter((status) =>
            tabData.filterObject?.selectedProjectStatus?.includes(status.value),
          )
          .map((status) => status.value),
      },
    };
    const response = await api.request({
      url: `/v3/invoice/dashboard/tab-filters`,
      method: "POST",
      data: payload,
    });

    loadingMessage();

    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.CREATE_TAB_FILTER_SUCCESS,
        payload: response.data.data,
      });

      message.success("Tab filter created successfully.");
    } else {
      message.error("Failed to create tab filter.");
    }
  } catch (error) {
    console.error("Failed to create tab filter:", error);
    message.error(
      "An error occurred while creating the tab filter. Please try again later.",
    );
  }
};

export const resetInvoiceData = () => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.RESET_INVOICE_REDUCER,
  });
};

export const editedPaymentReceiptId = (id) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SELECTED_PAYMENT_ID,
    payload: id,
  });
};

export const editRecordPaymentAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.EDIT_RECORD_PAYMENT,
    payload: data,
  });
};

export const editAllocationsAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.EDIT_ALLOCATIONS,
    payload: data,
  });
};

export const clearRecordNAllocation = () => async (dispatch) => {
  dispatch(editRecordPaymentAction([]));
  dispatch(editAllocationsAction([]));
};

export const isEditPaymentAction = (toggle) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.IS_EDIT_PAYMENT,
    payload: toggle,
  });
};

export const isEditPaymentAllocationAction = (toggle) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.IS_EDIT_ALLOCATION,
    payload: toggle,
  });
};

export const editInvoiceRowId = (id) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.EDIT_INVOICE_ID,
    payload: id,
  });
};

export const setDeletePaymentReason = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.DELETE_PAYMENT_REASON,
    payload: data,
  });
};

export const resetInvoiceDetailsData = () => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.RESET_INVOICE_DETAILS,
  });
};

export const setClonedAllocationDataAction = (data) => async (dispatch) => {
  dispatch({
    type: INVOICE_TYPE.SET_ALLOCATION_DATA,
    payload: data,
  });
};

export const saveAllocationData =
  ({ estimationId, setLoading }) =>
  async (dispatch, getState) => {
    const {
      paymentList,
      rowId,
      clonedAllocation,
      recordPayment,
      currentIndex: current,
      editRecordPayment,
      isEditAllocation,
    } = getState().invoice;
    if (setLoading) setLoading(true);
    const findPayment = paymentList?.find((payment) => payment?._id === rowId);
    const allocations = clonedAllocation
      ?.filter((obj) => obj?.allocation)
      ?.map((allocation) => ({
        invoiceId: allocation?._id,
        amount: allocation?.allocation,
      }));
    const formData = new FormData();

    const modifyKey = {
      paymentUpdatedNote: "paymentUpdatedNote",
      paymentAmount: "amount",
      paymentNote: "note",
      paymentDate: "paidDate",
      paymentMethod: "paymentMethod",
      proofOfPayments: "proofOfPayments",
      amount: "amount",
    };
    if (!findPayment) {
      formData.append("estimationId", estimationId);
    }
    if (recordPayment?.[current] || editRecordPayment?.[current]) {
      Object.keys(
        recordPayment?.[current] || editRecordPayment?.[current],
      ).forEach((key) => {
        if (findPayment && modifyKey[key]) {
          formData.append(modifyKey[key], editRecordPayment?.[current]?.[key]);
        }
        if (
          key === "proofOfPayments" &&
          editRecordPayment?.[current]?.[key]?.link
        ) {
          formData.delete("proofOfPayments");
        }
        if (!findPayment) {
          formData.append(key, recordPayment?.[current]?.[key]);
        }
      });
    }
    formData.append("allocations", JSON.stringify(allocations));
    const receiptStatus = paymentList?.find(
      (obj) => obj?._id === rowId,
    )?.receiptStatus;
    const response = await api.request({
      url: `v3/invoice/payment/${
        isEditAllocation
          ? "/allocation"
          : findPayment
          ? rowId
          : "create-with-allocation"
      }`,
      header: {
        "Content-Type": isEditAllocation
          ? "application/json"
          : "multipart/form-data",
      },
      method: findPayment && !isEditAllocation ? "PUT" : "POST",
      data: isEditAllocation ? { paymentId: rowId, allocations } : formData,
    });
    if (response.remote === "success") {
      dispatch(setEditRecordPaymentID(response?.data?.data?._id));
      dispatch(
        modalToggleAction({
          paymentAllocation: false,
        }),
      );
      // setUpdatedAllocationResToggle((prev) => !prev);
      // Call modal action if needed
      if (!findPayment) {
        dispatch(
          modalToggleAction({
            sendReceiptToggle: true,
          }),
        );
      } else {
        if (receiptStatus === "sent") {
          dispatch(modalToggleAction({ sendUpdatedReceipt: true }));
        }
      }
      dispatch(clearRecordPayment());
      dispatch(getInvoiceAndPaymentList(estimationId));
      dispatch(isEditPaymentAllocationAction(false));
      dispatch(disablePaginationAction(false));
      if (setLoading) setLoading(false);
      dispatch(clearRecordNAllocation());
      dispatch(editedPaymentReceiptId(""));
      dispatch(editInvoiceRowId(""));
      dispatch(isEditPaymentAction(false));
      message.success("Successfully allocate payment");
    } else {
      message.error(response?.data?.[0]?.message);
      if (setLoading) setLoading(false);
    }
  };

export const getSquareInfoAction =
  (token, invoiceId = "") =>
  async (dispatch) => {
    const response = await getSquareLocationId(token, invoiceId);
    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.SQUARE_PAYMENT_INFO,
        payload: response.data.data,
      });
    } else {
      message.error(response?.errors?.errors);
    }
  };

export const getSquareChargeAction = (token) => async (dispatch) => {
  const response = await getSquareCharge(token);
  if (response.remote === "success") {
    dispatch({
      type: INVOICE_TYPE.SQUARE_CHARGE_DETAILS,
      payload: response.data.data,
    });
  }
};

export const updateInvoiceReceiptDetailAction =
  (id, data) => async (dispatch, getState) => {
    const invoiceReceipt = getState()?.invoice?.sendReceipt;
    const receiptIndex = invoiceReceipt?.invoiceList?.findIndex(
      (obj) => obj?._id === id,
    );
    invoiceReceipt.invoiceList[receiptIndex] = {
      ...invoiceReceipt?.invoiceList?.[receiptIndex],
      paymentStatus: data?.status,
    };
    console.log("invoiceReceipt", invoiceReceipt);
    // dispatch({
    //   type: INVOICE_TYPE.EDIT_INVOICE_RECEIPT_DETAILS,
    //   payload:
    // });
  };

export const getGraphDetails =
  ({ timePeriod, graphType, setLoading, isInitial = false, loading = null }) =>
  async (dispatch) => {
    if (setLoading && !isInitial) {
      setLoading((prev) => ({ ...prev, [graphType]: true }));
    }
    if (setLoading && isInitial && loading) {
      Object.keys(loading).forEach((key) => {
        setLoading((prev) => ({
          ...prev,
          [key]: true,
        }));
      });
    }
    const response = await getGraphData({ timePeriod, graphType });
    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.GRAPH_DETAILS,
        payload: response?.data?.data,
      });
      if (setLoading && !isInitial) {
        setLoading((prev) => ({ ...prev, [graphType]: false }));
      }
      if (setLoading && isInitial && loading) {
        Object.keys(loading).forEach((key) => {
          setLoading((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
      }
    } else {
      if (setLoading && !isInitial) {
        setLoading((prev) => ({ ...prev, [graphType]: false }));
      }
      if (setLoading && isInitial && loading) {
        Object.keys(loading).forEach((key) => {
          setLoading((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
      }
    }
  };
