import urlcat from "urlcat";

import api from "./api";
const qs = require("qs");

export const getInvoicesAPI = async ({ estimationId, invoicesType }) => {
  const response = await api.request({
    url: urlcat("/v3/invoice", { estimationId, invoicesType }),
    method: "GET",
  });
  return response;
};
export const getInvoicesForCustomerAPI = async ({
  estimationId,
  invoicesType,
  token,
}) => {
  const response = await api.request({
    url: urlcat("/v3/invoice/customer", {
      estimationId,
      invoicesType,
      token,
    }),
    method: "GET",
  });
  return response;
};

export const updateInvoiceAPI = (data) =>
  api.request({
    url: "/v3/invoice",
    method: "POST",
    data,
  });
export const updateInvoiceForCustomerAPI = (data, token) =>
  api.request({
    url: urlcat("/v3/invoice/customer", { token }),
    method: "POST",
    data,
  });

export const getAllInvoices = async (params) => {
  /**
   * generate queryParams for server side filtering i.e page, limit, search etc.
   * params = {
      pageNumber: number,
      limit: number,
      search: string
    }
   */
  const queryParams = qs.stringify(params);
  const response = await api.request({
    url: `invoice/get-all?${queryParams}`,
    method: "GET",
  });
  return response;
};

export const getAllSubInvoices = async (invoiceId) => {
  const response = await api.request({
    url: `/invoice/get-all-sub-invoices/${invoiceId}`,
    method: "GET",
  });
  return response;
};

export const updateInvoice = async (data) => {
  const response = await api.request({
    url: "/invoice/update-invoice",
    method: "PUT",
    data,
  });
  return response;
};

export const updateSubInvoice = async (data) => {
  const response = await api.request({
    url: "/invoice/update-sub-invoice",
    method: "PUT",
    data,
  });
  return response;
};

export const updateInvoiceStatus = async (data) => {
  const response = await api.request({
    url: "/invoice/update-invoice-status",
    method: "PUT",
    data,
  });
  return response;
};

export const updateSubInvoiceStatus = async (data) => {
  const response = await api.request({
    url: "/invoice/update-sub-invoice-status",
    method: "PUT",
    data,
  });
  return response;
};

export const getInvoiceStatusList = async () => {
  const response = await api.request({
    url: "/invoice-status/list",
    method: "GET",
  });
  return response;
};

export const getAllTabs = async () => {
  const response = await api.request({
    url: "/invoice-tab/list",
    method: "GET",
  });
  return response;
};

export const addTab = async (data) => {
  const response = await api.request({
    url: "/invoice-tab/add",
    method: "POST",
    data,
  });
  return response;
};

export const removeTab = async (data) => {
  const response = await api.request({
    url: "/invoice-tab/delete",
    method: "DELETE",
    data,
  });
  return response;
};

export const getInvoiceById = async (invoiceId) => {
  const response = await api.request({
    url: `/invoice/get-invoice-by-id/${invoiceId}`,
    method: "GET",
  });
  return response;
};

export const getSubinvoiceInvoiceById = async (invoiceId) => {
  const response = await api.request({
    url: `/invoice/get-subinvoice-by-id/${invoiceId}`,
    method: "GET",
  });
  return response;
};

export const saveFilters = async (data, id) => {
  const response = await api.request({
    url: `/invoice-tab/update/${id}`,
    method: "POST",
    data,
  });
  return response;
};

export const expectedPayment = async () => {
  const response = await api.request({
    url: "/invoice/expected-payment",
    method: "GET",
  });
  return response;
};

export const PDFsend = async (data) => {
  const response = await api.request({
    url: "/invoice/upload-send-invoice",
    method: "POST",
    data,
  });
  return response;
};

export const sendSubinvoiceToCustomer = async (data) => {
  const response = await api.request({
    url: "/invoice/upload-send-subinvoice",
    method: "POST",
    data,
  });
  return response;
};

export const filterUpdate = async (id, data) => {
  const response = await api.request({
    url: `/invoice-tab/update/${id}`,
    method: "POST",
    data,
  });
  return response;
};

export const getFilters = async (id, data) => {
  const response = await api.request({
    url: `/invoice-tab/list/${id}`,
    method: "GET",
    data,
  });
  return response;
};

export const filterResult = async (data, page, limit) => {
  const response = await api.request({
    url: `/invoice/filter?pageNumber=${page}&limit=${limit}`,
    method: "POST",
    data,
  });
  return response;
};

export const addNewLine = async (data) => {
  const response = await api.request({
    url: "/invoice/addNewlineInvoice",
    method: "POST",
    data,
  });
  return response;
};

export const sendInvoice = async (id, data) => {
  const response = await api.request({
    url: `/invoice/share-invoice/${id}`,
    method: "POST",
    data,
  });
  return response;
};

export const generateSharingLinkForSubInvoice = async (id, data) => {
  const response = await api.request({
    url: `/invoice/share-sub-invoice/${id}`,
    method: "POST",
    data,
  });
  return response;
};

export const sendInvoiceAfterClick = async (data) => {
  const response = await api.request({
    url: "/invoice/share-invoice-by-email",
    method: "POST",
    data,
  });
  return response;
};

export const sendInvoiceOnSMS = async (data) => {
  const response = await api.request({
    url: "/invoice/share-invoice-by-sms",
    method: "POST",
    data,
  });
  return response;
};

export const paynowSubInvoice = async (data) => {
  const response = await api.request({
    url: "/invoice/payment",
    method: "POST",
    data,
  });
  return response;
};

export const getInvoicesSettingDetails = async (data) => {
  const response = await api.request({
    url: "/v3/invoice/settings",
    method: "GET",
    data,
  });
  return response;
};
export const payment = async (data) => {
  const response = await api.request({
    url: "/v3/invoice/settings/payment-integration",
    method: "post",
    data,
  });
  return response;
};
export const stripeUrlDetail = async (query) => {
  const response = await api.request({
    url: `/v3/invoice/settings/payment-integration?integrationType=${query}`,
    method: "GET",
  });
  return response;
};
export const removeStripDetailsAPI = async () => {
  const response = await api.request({
    url: `/v3/invoice/settings/payment-integration`,
    method: "DELETE",
  });
  return response;
};

export const getEmailTemplatesDetails = async (data) => {
  const response = await api.request({
    url: "/v3/settings/email-templates/list",
    method: "GET",
    data,
  });
  return response;
};

export const updateInvoicesSettingDetails = async (data) => {
  const response = await api.request({
    url: "/v3/invoice/settings",
    method: "PUT",
    data,
  });
  return response;
};

export const markVisibleEmailDetails = async (token) => {
  const response = await api.request({
    url: `v3/contract/sign-contract/deposit-popup-viewed/${token}`,
    method: "PUT",
  });
  return response;
};

export const viewedReceipt = async (token) =>
  api.request({
    url: `v3/invoice/customer/mark-view-receipt?token=${token}`,
    method: "PUT",
  });

export const getChangeOrderTemplateDetail = async ({
  templateId,
  estimationId,
  changeOrderSentId,
  selectedServicesIds,
  actualServicesId,
}) => {
  const url = urlcat("v3/user-estimate/change-order-template", {
    templateId,
    estimationId,
    changeOrderSentId,
    selectedServicesIds,
    actualServicesId: actualServicesId.join(","),
  });

  const response = await api.request({
    url,
    method: "GET",
  });
  return response;
};

export const deleteRecordedPayment = async ({ id, deletedReason }) => {
  const response = await api.request({
    url: `v3/invoice/payment/${id}`,
    method: "DELETE",
    data: {
      deletedReason,
    },
  });
  return response;
};

export const deletePaymentProof = async ({ id, deletedReason }) => {
  const response = await api.request({
    url: `v3/invoice/payment/proof/${id}`,
    method: "DELETE",
    data: {
      deletedReason,
    },
  });
  return response;
};

export const deleteInvoice = async ({ invoiceId, deletedReason }) => {
  const response = await api.request({
    url: `v3/invoice/${invoiceId}`,
    method: "DELETE",
    data: {
      deletedReason,
    },
  });
  return response;
};

export const setInvoiceDueDate = async ({ id, data }) => {
  const response = await api.request({
    url: `v3/invoice/invoice-due-date/${id}`,
    method: "PUT",
    data,
  });
  return response;
};
export const generateInvoices = async (data) => {
  const response = await api.request({
    url: `v3/user-estimate/signed/generate-invoicing`,
    method: "POST",
    data: {
      signedEstimationIds: [...data],
    },
  });
  return response;
};

export const updatePaymentTypes = async (invoiceId, data) => {
  const response = await api.request({
    url: `v3/invoice/payment-integration/${invoiceId}`,
    method: "PUT",
    data,
  });
  return response;
};

export const generateStripePaymentLink = async (
  token,
  type,
  invoiceId = null,
) => {
  const response = await api.request({
    url: `v3/invoice/customer/payment-link?token=${token}&paymentMethod=${type}${
      invoiceId ? `&invoiceId=${invoiceId}` : ""
    }`,
    method: "GET",
  });

  return response;
};

export const isQBSync = async () => {
  const response = await api.request({
    url: `v3/invoice/settings/qb-sync`,
    method: "GET",
  });
  return response;
};

export const removeQBSync = async () => {
  const response = await api.request({
    url: `v3/invoice/settings/qb-sync`,
    method: "DELETE",
  });
  return response;
};

export const isQBSyncPost = async ({ data }) => {
  const response = await api.request({
    url: `v3/invoice/settings/qb-sync`,
    method: "POST",
    data,
  });
  return response;
};

export const generateSquarePaymentLink = async ({
  token,
  type,
  invoiceId = "",
}) => {
  const response = await api.request({
    url: `v3/invoice/customer/square-payment-link?token=${token}&paymentMethod=${type}${
      invoiceId ? `&invoiceId=${invoiceId}` : ""
    }`,
    method: "GET",
  });
  return response;
};

export const getSquareLocationId = async (token, invoiceId = "") => {
  const response = await api.request({
    url: `v3/invoice/customer/square-account-location?token=${token}${
      invoiceId ? `&invoiceId=${invoiceId}` : ""
    }`,
    method: "GET",
    needReload: false,
  });
  return response;
};

export const getSquareCharge = async (token, data, invoiceId = "") => {
  const response = await api.request({
    url: `v3/invoice/customer/square-charge?token=${token}${
      invoiceId ? `invoiceId=${invoiceId}` : ""
    }`,
    method: "POST",
    data,
  });
  return response;
};

export const getQBToken = async (data) => {
  const response = await api.request({
    url: `${process.env.REACT_APP_QB_TOKEN_ENDPOINT}/quickbooks/v4/payments/tokens`,
    method: "POST",
    data,
  });
  return response;
};

export const getQBCharge = async (
  token,
  data,
  paymentMethod = "CARD",
  invoiceId = "",
) => {
  const response = await api.request({
    url: `v3/invoice/customer/qb-charge?token=${token}${
      invoiceId ? `invoiceId=${invoiceId}` : ""
    }${paymentMethod ? `paymentMethod=${paymentMethod}` : ""}`,
    method: "POST",
    data,
  });
  return response;
};

export const generateQBPaymentLink = async ({
  token,
  type,
  invoiceId = null,
  billingEmail = "",
}) => {
  const response = await api.request({
    url: `v3/invoice/customer/qb-payment-link?token=${token}&paymentMethod=${type}${
      invoiceId ? `&invoiceId=${invoiceId}` : ""
    }${billingEmail ? `&billingEmail=${billingEmail}` : ""}`,
    method: "POST",
  });

  return response;
};

export const getGraphData = async ({ timePeriod, graphType }) => {
  const response = await api.request({
    url: `v3/invoice/dashboard/graphs?timePeriod=${timePeriod}${
      graphType ? `&graphType=${graphType}` : ""
    }`,
    method: "GET",
  });

  return response;
};
