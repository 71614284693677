export const INVOICE_TYPE = {
  ESTIMATION_CHANGE_ORDER: "invoice/estimation-change-order",
  ESTIMATION_CHANGE_ORDER_PENDING: "invoice/estimation-change-order-pending",
  LOADING: "invoice/loading",
  INVOICE_LIST: "invoice/invoice-list",
  SEND_RECEIPT: "invoice/unpaid-partial-paid-receipt",
  SHOW_BANNER: "invoice/show-alert-banner",
  SHOW_WARNING_BANNER: "invoice/warning-alert-banner",
  DELETE_INVOICE: "invoice/delete-invoice",
  INVOICE_EMAIL_CATEGORY: "invoice/email-categories",
  RECEIPT_DETAILS: "invoice/receipt-details",
  SUMMARY_DETAILS: "invoice/invoice-summary-details",
  MODAL_TOGGLE: "invoice/toggle-modal",
  PROJECT_DETAILS: "project_deatils",
  TAGS: "invoice/note-tags",
  SET_EDIT_PAYMENT_ID: "invoice/set-record-payment-id",
  EDIT_INVOICE_ROW: "invoice/edit-invoice-row",
  ADD_INVOICE_ROW: "invoice/add-invoice-row",
  SET_UNPAID_SUMMARY: "invoice/unpaid-summary-details",
  SET_RECORD_PAYMENT: "invoice/record-unpaid-invoice",
  CLEAR_RECORD_PAYMENT: "invoice/clear-record-payment",
  DISABLED_PAGINATION: "invoice/disabled-record-payment-pagination",
  CURRENT_INDEX: "invoice/record-current-index",
  IS_EDIT_RECORD_PAYMENT: "invoice/is-record-edit-modal",
  INTERNAL_NOTE: "invoice/internal-note-change",
  DELETE_INVOICE_REASON: "invoice/delete-invoice-reason",
  PAYMENT_LIST: "invoice/payment-list",
  PROJECT_STATUSES: "invoice/projectStatuses",
  SET_LOADING_PROJECT_STATUSES: "invoice/setLoadingProjectStatuses",
  SET_SELECTED_FILTERS: "invoice/setSelectedFilters",
  SET_TABS_DATA: "setTabsData",
  SET_TABLE_INVOICES: "invoice/setUnmodifiedTableInvoices",
  SET_LOADING: "invoice/setLoading",
  ADD_TAB_SUCCESS: "Add/tab/success",
  DELETE_TAB_SUCCESS: "Delete/tab/success",
  CREATE_TAB_FILTER_SUCCESS: "create/tab/filter/success",
  UPDATE_TAB: "update/tab",
  RESET_INVOICE_REDUCER: "invoice/reset-reducer",
  SELECTED_PAYMENT_ID: "invoice/selected-payment-id",
  EDIT_RECORD_PAYMENT: "invoice/edit-record-payment",
  EDIT_ALLOCATIONS: "invoice/edit-allocation",
  IS_EDIT_PAYMENT: "invoice/is-edit-payment",
  IS_EDIT_ALLOCATION: "invoice/is-edit-allocation",
  EDIT_INVOICE_ID: "invoice/edit-invoice-id",
  DELETE_PAYMENT_REASON: "invoice/payment-delete-reason",
  RESET_INVOICE_DETAILS: "invoice/reset-invoice-details",
  SET_ALLOCATION_DATA: "invoice/set-allocation-data",
  SQUARE_PAYMENT_INFO: "invoice/square-payment-info",
  SQUARE_CHARGE_DETAILS: "invoice/square-charge-details",
  EDIT_INVOICE_RECEIPT_DETAILS: "invoice/update-invoice-receipt-details",
  GRAPH_DETAILS: "invoice/graph-details",
};
