import axios from "axios";

import { SERVER_URL } from "../config";
import store from "../store";

const baseURL = SERVER_URL;
const axiosInstance = async () => {
  const instance = axios.create({
    baseURL,
  });
  return instance;
};

const parseResponse = (response) => {
  try {
    const data = JSON.parse(response);
    if (data?.errors) {
      return {
        remote: "failure",
        error: {
          errors: data.errors,
        },
      };
    }
    return {
      remote: "success",
      data,
    };
  } catch (error) {
    return {
      remote: "failure",
      error: {
        errors: response,
      },
    };
  }
};

const request = async (config) => {
  if (config?.needReload === undefined) {
    config.needReload = true;
  }
  try {
    const token = config?.token || localStorage.getItem("token");
    const instance = await axiosInstance();
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    if (!config.headers["X-Client-IP"]) {
      const { user } = store.getState();
      if (!user.ip) {
        try {
          const clientIpAddress = await axios({
            method: "GET",
            url: "https://api.ipify.org?format=json",
            timeout: 2000,
          });
          // store-*
          config.headers["X-Client-IP"] = clientIpAddress.data.ip;
          store.dispatch({
            type: "user/updateUserIp",
            payload: clientIpAddress.data.ip,
          });
        } catch (error) {
          // console.log("Error fetching userIp");
        }
      } else {
        config.headers["X-Client-IP"] = user.ip;
      }
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Client-Time"] = Date.now();
    config.headers["Client-Time-Zone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await instance.request({
      ...config,
      transformResponse: (res) => {
        const resp = parseResponse(res);
        return resp.remote === "success" ? resp.data : resp;
      },
    });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    if (error) {
      if (error?.response) {
        const axiosError = error;
        if (axiosError?.response?.data) {
          let errorMessage = axiosError?.response?.data?.errors;
          if (axiosError?.response?.status === 500) {
            errorMessage =
              axiosError?.response?.data?.error || "Internal Server Error";
          } else if (
            axiosError?.response?.status === 401 &&
            config?.needReload
          ) {
            errorMessage = "Forbidden";
            localStorage.clear();
            window.location.reload();
          } else if (
            axiosError?.response?.status === 401 &&
            !config.needReload
          ) {
            errorMessage = axiosError?.response?.data?.error;
          } else {
            errorMessage =
              error?.response?.data?.error?.errors ||
              axiosError?.response?.data;
          }
          return {
            remote: "failure",
            errors: {
              status: axiosError?.response?.status,
              errors: errorMessage,
            },
          };
        }
      }
    } else {
      const axiosError = error;
      let errorMessage = axiosError.message;
      if (errorMessage === "Network Error") {
        errorMessage = "No internet connection";
      }
      return {
        remote: "failure",
        errors: {
          errors: errorMessage,
        },
      };
    }
    return error;
    // I comment this line because when user don't have internet connect that time it throw error so in FE code break so error message code not perform because of this so.
    // throw error;
  }
};

const requestByToken = async (config) => {
  console.log("config => ", config);
  if (config.needReload === undefined) {
    config.needReload = true;
  }
  try {
    const token = config.token || localStorage.getItem("token");
    const instance = await axiosInstance();
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Client-Time"] = Date.now();
    config.headers["Client-Time-Zone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await instance.request({
      ...config,
      transformResponse: (res) => {
        const resp = parseResponse(res);
        return resp.remote === "success" ? resp.data : resp;
      },
    });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    if (error) {
      if (error?.response) {
        const axiosError = error;
        if (axiosError?.response?.data) {
          let errorMessage = axiosError?.response?.data?.errors;
          if (axiosError?.response?.status === 500) {
            errorMessage = "Internal Server Error";
          } else if (axiosError?.response?.status === 401) {
            errorMessage = "Forbidden";
          } else {
            errorMessage =
              error?.response?.data?.error?.errors ||
              axiosError?.response?.data;
          }
          return {
            remote: "failure",
            errors: {
              status: axiosError?.response?.status,
              errors: errorMessage,
            },
          };
        }
      }
    } else {
      const axiosError = error;
      let errorMessage = axiosError.message;
      if (errorMessage === "Network Error") {
        errorMessage = "No internet connection";
      }
      return {
        remote: "failure",
        errors: {
          errors: errorMessage,
        },
      };
    }
    throw error;
  }
};

const toExport = {
  request,
  requestByToken,
  parseResponse,
};

export default toExport;
