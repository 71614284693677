const backupKeyPlan = {
  estimating: 97.99,
  essential: 197.99,
  estimatingYearly: 979.9,
  essentialYearly: 1979.9,
};
export const planPriceSelection = {
  ...backupKeyPlan,
  estimatingYearlyPlan: 979.9,
  essentialYearlyPlan: 1979.9,
  estimatingPlan: 97.99,
  essentialPlan: 197.99,
};
export const planPriceDropdown = [
  {
    value: "essentialYearlyPlan",
    label: "Essential - Annual Plan",
  },
  {
    value: "estimatingYearlyPlan",
    label: "Estimating - Annual Plan",
  },
  {
    value: "essentialPlan",
    label: "Essential - Monthly Plan",
  },
  {
    value: "estimatingPlan",
    label: "Estimating - Monthly Plan",
  },
];
