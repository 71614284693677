import api from "./api";
import { transformUserResponse } from "./transform/user";

export async function updateCustomerStatus(data) {
  const response = await api.request({
    url: "/auth/update-status",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updatePassword(data) {
  const response = await api.request({
    url: "/v3/hrms/employee/reset-password",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateForgotPassword(data) {
  const response = await api.request({
    url: "/v3/auth/forgot-password",
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function approveEmployee(data) {
  const response = await api.request({
    url: "/invitation/approve-employee",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function changePasswordProfile(data) {
  const response = await api.request({
    url: "/admin/update-password",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function updateIsAdminStatus(data) {
  const response = await api.request({
    url: "/auth/update-isadmn-status",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function resetPassword(data) {
  const response = await api.request({
    url: "v3/auth/send-forgot-password-link",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getCurrentUser() {
  const response = await api.request({
    url: "/auth/get-user-details",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformUserResponse(response.data.user),
    };
  }
  return null;
}

export async function getSetupCurrentUser(data) {
  const response = await api.request({
    url: "/auth/get-user-setup-details",
    method: "post",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.user,
    };
  }
  return null;
}

export async function updateAllData(data) {
  const response = await api.request({
    url: "/auth/update",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function updateAllAdminData(data) {
  const response = await api.request({
    url: "/v3/admin/master",
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function updateVideoStatus(data) {
  const response = await api.request({
    url: "/v3/organization/guidons-video-status",
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllUserRole() {
  const response = await api.request({
    url: "/user-role/list-user-role",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return null;
}

export async function addNewStaff(data) {
  const response = await api.request({
    url: "/staff/add-staff",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function checkStaffEmail(data) {
  const response = await api.request({
    url: "/v3/hrms/employee/is-duplicate-email",
    method: "POST",
    data,
  });

  return response;
}

export async function sendInvite(data) {
  const response = await api.request({
    url: "/v3/hrms/employee/invite",
    method: "POST",
    data,
  });

  return response;
}
export async function updateEmployeeData(employeeId, data) {
  const response = await api.request({
    url: `v3/hrms/employee/${employeeId}`,
    method: "PUT",
    data,
  });

  return response;
}

export async function updateWalletBalance(data) {
  const response = await api.request({
    url: "/auth/update-wallet-balance",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateFirstTimeAccessField(data) {
  const response = await api.request({
    url: "v3/auth/first-time-access",
    method: "patch",
    data,
  });
  return response;
}

export async function getOrganizationUserList() {
  const response = await api.request({
    url: "v3/organization/get-organization-userList",
    method: "get",
  });
  return response;
}

export const getSubscriptionDetails = async () => {
  const response = await api.request({
    url: "v3/subscription/permission",
    method: "GET",
  });
  return response;
};

export const verifyTokenDetail = async (data) => {
  const response = await api.request({
    url: "v3/auth/verify-token",
    method: "POST",
    data,
    needReload: false,
  });
  return response;
};
