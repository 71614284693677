import API_TYPES from "./api.types";

export const setAPIKey = (data) => ({
  type: API_TYPES.SET_API,
  payload: data,
});

export const setLoggedinState = (data) => ({
  type: API_TYPES.SET_LOGIN_STATE,
  payload: data,
});

export const setCdnToken = (data) => ({
  type: API_TYPES.SET_CDN_TOKEN,
  payload: data,
});

export const setPermissions = (data) => ({
  type: API_TYPES.SET_PERMISSION,
  payload: { ...data },
});
