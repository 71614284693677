import jwt from "jsonwebtoken";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getEstimateProjectSummaryDetailsForCustomerAPI,
  getEstimationDetailsWithSentChangeOrderDetailsForCustomerAPI,
} from "../../api/estimation";
import { getInvoicesForCustomerAPI } from "../../api/invoice";
import { DATE_FORMATE } from "../../utils/constants/constants";
import services from "../../utils/services";
import SmallLoader from "../loader/smallLoader";
import Content from "./content";

function SignChangeOrder() {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");

  const [preparingUserDetails] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);

  const [changeOrderSentId, setChangeOrderSentId] = useState("");
  const [estimationId, setEstimationId] = useState("");
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const [actualServicesId, setActualServicesId] = useState([]);

  const [projectSummaryData, setProjectSummaryData] = useState([]);

  const [totalChangedOrderWithoutTax, setTotalChangedOrderWithoutTax] =
    useState(0);
  const [totalChangedTax, setTotalChangedTax] = useState(0);
  const [estimationDetails, setEstimationDetails] = useState({});
  const [invoices, setInvoices] = useState([]);

  const getEstimateDetails = async (
    { estimationId, changeOrderSentId, selectedServicesIds },
    stopLoading,
  ) => {
    if (!stopLoading) {
      setLoading(true);
    }
    const res =
      await getEstimationDetailsWithSentChangeOrderDetailsForCustomerAPI({
        estimationId,
        changeOrderSentId,
      });
    if (res.remote === "success") {
      setEstimationDetails(res.data.data);
      const selectedServices = res.data.data.services.filter((service) =>
        selectedServicesIds.includes(service.draggableId),
      );
      let totalChangeOrderWithoutTax = 0;
      let totalChangedTax = 0;

      selectedServices.forEach((service) => {
        totalChangeOrderWithoutTax +=
          service.actualServices[0].changedPriceBreakdown.totalServiceCharge;
        totalChangedTax +=
          service.actualServices[0].changedPriceBreakdown
            .totalServiceChargeWithTax -
          service.actualServices[0].changedPriceBreakdown.totalServiceCharge;
      });
      // eslint-disable-next-line no-unreachable
      setTotalChangedOrderWithoutTax(totalChangeOrderWithoutTax);
      setTotalChangedTax(totalChangedTax);

      setSelectedServices(selectedServices);
      getInvoices({
        estimationId,
        invoiceSplit: res.data.data.changeOrderDetails,
      });
    }
  };

  const getInvoices = async ({ estimationId, invoiceSplit }) => {
    const { token } = decodeToken();
    const res = await getInvoicesForCustomerAPI({
      estimationId,
      invoicesType: "unpaid",
      token,
    });
    if (res.remote === "success") {
      let invoices = res.data.data.map((invoice) => {
        const isUpdatedInvoice = invoiceSplit.invoicePercentageSplit.find(
          (inv) => inv.invoiceId === invoice._id,
        );
        if (isUpdatedInvoice && !invoiceSplit.signedByClient) {
          const splitAmount =
            (isUpdatedInvoice.percentage * invoiceSplit.changedPrice) / 100;
          return {
            key: invoice._id,
            invoiceName: invoice.invoiceName,
            invoiceNumber: invoice.invoiceNumber,
            invoiceStatus: invoice.invoiceStatus,
            invoiceDate: isUpdatedInvoice.invoiceDate
              ? moment(isUpdatedInvoice.invoiceDate).format(DATE_FORMATE)
              : "-",
            invoiceISODate: isUpdatedInvoice.invoiceDate,
            isInvoiceDateChanged:
              isUpdatedInvoice.invoiceDate !== invoice.invoiceDate,
            dueDate: isUpdatedInvoice.dueDate,
            terms: isUpdatedInvoice.terms,
            currentBalance: invoice.amount,
            difference: splitAmount,
            newBalance: invoice.amount + splitAmount,
            details: invoice,
          };
        }
        return {
          key: invoice._id,
          invoiceName: invoice.invoiceName,
          invoiceNumber: invoice.invoiceNumber,
          invoiceStatus: invoice.invoiceStatus,
          invoiceDate: invoice.invoiceDate
            ? moment(invoice.invoiceDate).format(DATE_FORMATE)
            : "-",
          currentBalance: invoice.amount,
          diffPercentage: 0,
          newBalance: invoice.amount,
          details: invoice,
        };
      });

      if (!invoiceSplit.signedByClient) {
        const newInvoices = invoiceSplit.invoicePercentageSplit.filter(
          (inv) => !inv.invoiceId,
        );
        if (newInvoices.length) {
          invoices = [
            ...invoices,
            ...newInvoices.map((inv, idx) => {
              const splitAmount =
                (inv.percentage * invoiceSplit.changedPrice) / 100;
              return {
                key: idx,
                invoiceName: inv.name,
                invoiceNumber: inv.invoiceNumber,
                invoiceStatus: "notDue",
                invoiceDate: inv.invoiceDate
                  ? moment(inv.invoiceDate).format(DATE_FORMATE)
                  : "-",
                invoiceISODate: inv.invoiceDate,
                dueDate: inv.dueDate,
                terms: inv.terms,
                isInvoiceDateChanged: !!inv.invoiceDate,
                currentBalance: 0,
                difference: splitAmount,
                newBalance: splitAmount,
              };
            }),
          ];
        }
        invoices = invoices.map((invoice, idx) => {
          if (!invoice.invoiceNumber && idx - 1 >= 0) {
            const oldInvoiceNumber = invoices[idx - 1].invoiceNumber;
            const [start, mid, post, number] = oldInvoiceNumber.split("-");
            const newNumber = Number(number) + 1;
            const newInvoiceNumber = `${start}-${mid}-${post}-${
              newNumber < 10 ? `0${newNumber}` : newNumber
            }`;
            invoice.invoiceNumber = newInvoiceNumber;
          }
          return invoice;
        });
        invoices.sort((a, b) => {
          const dateA = a.invoiceDate !== "-" ? new Date(a.invoiceDate) : null;
          const dateB = b.invoiceDate !== "-" ? new Date(b.invoiceDate) : null;

          if (dateA && dateB) {
            return dateA - dateB;
          }
          if (dateA) {
            return -1;
          }
          if (dateB) {
            return 1;
          }
          return a.invoiceNumber.localeCompare(b.invoiceNumber);
        });
      }
      setInvoices(invoices);
    }
    setLoading(false);
  };

  const getProjectSummaryData = async ({ estimationId, uniqueIds }) => {
    const res = await getEstimateProjectSummaryDetailsForCustomerAPI({
      estimationId,
      uniqueIds,
    });
    console.log({ res });
    if (res.remote === "success") {
      setProjectSummaryData(res.data.data);
    }
  };

  //   useEffect(() => {
  //     if (userDetails._id) {
  //       setPreparingUserDetails(userDetails);
  //     }
  //   }, [userDetails]);

  const decodeToken = () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    try {
      const data = jwt.verify(token, services.JWT_KEY);
      return { data: data.data, token };
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    const { data: paramsData, token } = decodeToken();
    const { changeOrderSentId, estimationId, actualServicesId } = paramsData;
    const selectedServicesIds = paramsData.selectedServices;
    setChangeOrderSentId(changeOrderSentId);
    setEstimationId(estimationId);
    setSelectedServicesIds(selectedServicesIds);
    setActualServicesId(actualServicesId);
    setToken(token);

    getEstimateDetails({
      estimationId,
      changeOrderSentId,
      selectedServicesIds,
    });

    getProjectSummaryData({
      estimationId,
      uniqueIds: actualServicesId,
    });
  }, []);
  return (
    <div
      // style={{
      //   padding: "25px",
      //   background: "#F7F7F7",
      //   display: "flex",
      //   flexDirection: "column",
      // }}
      style={{ clear: "both" }}
      className="max-center-change-order"
    >
      {loading ? (
        <SmallLoader />
      ) : (
        <Content
          preparingUserDetails={preparingUserDetails}
          selectedServices={selectedServices}
          changeOrderSentId={changeOrderSentId}
          estimationId={estimationId}
          selectedServicesIds={selectedServicesIds}
          actualServicesId={actualServicesId}
          projectSummaryData={projectSummaryData}
          totalChangedOrderWithoutTax={totalChangedOrderWithoutTax}
          totalChangedTax={totalChangedTax}
          estimationDetails={estimationDetails}
          invoices={invoices}
          getEstimateDetails={getEstimateDetails}
          token={token}
        />
      )}
      <div style={{ clear: "both" }}></div>
    </div>
  );
}

export default SignChangeOrder;
